import "./App.css";
import SideNav from "./components/nav/SideNav";
import { BrowserRouter as Router } from "react-router-dom";
import { Row, Col, Layout, Affix, Spin } from "antd";
import { observer } from "mobx-react-lite";
import TopNav from './components/nav/TopNav'
import RouteSwitcher from "./components/nav/routeSwitcher";
import { useWindowSize } from "./hooks/CustomHooks";
import dbstore from "./dataLayer/dbstore";
import { LoadingOutlined } from "@ant-design/icons";
import Login from "./components/routes/Login";
// import { store } from "./stores/store";
// import "antd/dist/antd.css";
import GetTheApps from "./components/GetTheApps";
import HelpStore from "./dataLayer/help";
import { useEffect } from "react";

const { Header, Content, Footer, Sider } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
dbstore.refreshStore()
const App = observer(() => {

  useEffect(() => {
    // const listener = HelpStore.listener();
    // return () => {
    //   listener()
    // }
  }, [])
  const [width, height] = useWindowSize();
  return (
    <>
      {width <= 1270 ? (
        <GetTheApps />
      ) : <Router basename="/admin">
        {!dbstore.login && <Login />}
        {dbstore.login && <Layout style={{ minHeight: "100vh" }}>
          <Row>
            <Col span={4}>
              <Affix>
                <SideNav />
              </Affix>
            </Col>
            <Col span={20}>
              <Row>
                <Col span={24}>
                  <Affix>
                    <TopNav />
                  </Affix>
                </Col>
              </Row>
              <Spin
                tip="Loading..."
                // spinning={dbstore.loading}
                spinning={false}

                indicator={antIcon}
              >
                {/* <Row style={{
                  padding: 50,
                  paddingTop: 20,
                  paddingRight: 70,
                  paddingLeft: 70,
                }}> */}
                <RouteSwitcher />
                {/* </Row> */}
              </Spin>

            </Col>
          </Row>
        </Layout>}
      </Router>
      }
    </>
  );
});
export default App;
