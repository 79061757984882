
import {makeAutoObservable} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
class Store {
    constructor() {
        makeAutoObservable(this, {

        });
    }

    endpoint = dbstore.endpoint;
    reconcilerJobsData = {};
    isJobDataLoading = false;

    reconcileJobsData = async (params) => {
        this.isJobDataLoading = true;
        const {
            startDate = moment().format("YYYY-MM-DD"),
            endDate = moment().format("YYYY-MM-DD"),
        } = params;

        dbstore.checkSession();

        let requestBody = {
            startDate: startDate,
            endDate: endDate,
        }
        var path = '/rxreconciler';
        var options = {
            method: "post",
            cache: "no-cache",
            credentials: 'include',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestBody)
        }

        var res = await fetch(
            `${this.endpoint}${path}`,
            options,

        );
        res = await res.json();
        this.reconcilerJobsData = res;
        this.isJobDataLoading = false;

    }

}

const ReconcilerStore = new Store();
export default ReconcilerStore;