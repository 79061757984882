import { Col, Row, Tabs } from "antd";
import React from "react";
import Styles from "../../Styles/HomeStyle.module.css";
import ReconcilerDataView from "./ReconcilerDataView";
import Reports from "./reports";

const TabPane = Tabs.TabPane;

const tabs = [
  {
    name: "Reconciler",
    value: "reconciler",
    content: <ReconcilerDataView />,
  },
  {
    name: "Reports",
    value: "reports",
    content: <Reports />,
  },
];

const ReportsWrapper = () => {
  return (
    <div style={Styles?.main}>
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <Tabs
            animated
          >
            {tabs.map((eachTab) => (
              <TabPane
                key={eachTab.value}
                tab={
                  <div >
                    <div>
                      <h4>{eachTab.name}</h4>
                    </div>
                  </div>
                }
              >
                {eachTab.content}
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsWrapper;
