import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from '../Styles/HomeStyle.module.css';
import { observer } from "mobx-react";
const DropInput = observer(({ showSelect = true,
    placeholder = "Enter Last name,SSN, Date of Birth",
    onChange = () => { },
    value = '' }) => {
    return (
        <>
            <div className={Styles.inputfield}>
                <div>
                    <FontAwesomeIcon icon={'search'} size={'lg'} />
                    <input
                        value={value}
                        onChange={(e) => {
                            onChange(e.target.value)
                        }} placeholder={placeholder}></input>
                </div>
                {showSelect && <div>
                    <select>
                        <option>
                            None
                        </option>
                        <option>
                            Compilance
                        </option>
                    </select>
                </div>}

            </div>
        </>
    );
}
)
export default DropInput;