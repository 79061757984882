import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Styles from './ModalStyle.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-date-picker';
import homeStore from "../../dataLayer/home";
import { observer } from "mobx-react";

const inputStyle = {
    flex: 0.5,
    padding: '1em',
    background: '#f1f1f1',
    outline: 'none',
    margin: '0.5em',
    border: 'none',
    borderRadius: '1em',
    fontSize: '1.2em',
}
const AddModal = observer((props) => {
    // const [show,setShow] = useState(props?.show);

    const handleClose = () => {
        props?.handleClose();
    }
    const handleAdd = () => {
        props?.handleAdd();
    }
    return (
        <>
            <div>
                <Modal show={props?.show} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    style={{ borderRadius: "5em" }}
                    size={"lg"}
                >
                    <Modal.Header>
                        <Modal.Title style={{ flex: 1, alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", padding: ".5em 1em" }}>
                                <h2 style={{ flex: 1, fontWeight: "bold" }}>{props?.title}</h2>
                                <div>
                                    <FontAwesomeIcon icon={"times"} onClick={handleClose}></FontAwesomeIcon>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={Styles.fieldsection}>
                            <div>
                                <input
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('firstName', e.target.value)

                                    }}
                                    style={inputStyle} placeholder="Enter First Name"></input>
                                <input
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('lastName', e.target.value)

                                    }}
                                    style={inputStyle} placeholder="Enter Last Name"></input>
                            </div>
                            <div>

                                <input
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('dateOfBirth', e.target.value)

                                    }}
                                    style={inputStyle} placeholder="Enter Date of birth(mm/dd/yyyy)"></input>
                                {/* <input style={inputStyle} placeholder="Choose Gender"></input> */}
                                <select style={inputStyle} name="cars" id="cars"
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('gender', e.target.value)

                                    }}>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>

                                </select>
                            </div>
                            <div>
                                <input
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('phone', e.target.value)

                                    }}
                                    style={inputStyle} placeholder="Enter Phone no."></input>
                                <input
                                    onChange={(e) => {
                                        console.log(e.target.value);
                                        homeStore.setInputData('email', e.target.value)

                                    }}
                                    style={inputStyle} placeholder="Enter Email ID"></input>
                            </div>
                            {/* <div>
                                <input placeholder="Address Line 1"></input>
                                <input placeholder="Address Line 2"></input>
                            </div>
                            <div>
                                <input placeholder="Clinic Address Line 1"></input>
                                <input placeholder="Clinic Address Line 2"></input>
                            </div> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ padding: "1em 2em" }}>
                        <a onClick={handleAdd} style={{ background: "#23d098", padding: "1em 3em", borderRadius: "1em", border: "none", color: "#fff", fontWeight: "bold", fontSize: "1.2em" }}>Add</a>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    );
})

export default AddModal;