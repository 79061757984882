import {
  Col,
  Row,
  Empty,
  Modal,
  Button,
  Switch,
  Tag,
  Table,
  Typography,
  Space,
  message
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import Styles from "../Styles/HomeStyle.module.css";
import DrListItem from "../common/DrListItem";
import Msg from "../../assets/animations/drp1/Icon material-email.png";
import Phone from "../../assets/animations/drp1/Icon awesome-phone-alt.png";
import MapIcon from "../../assets/animations/drp1/Icon awesome-map-marked-alt.png";
import Message from "../../assets/animations/drp1/Icon ionic-ios-chatbubbles.png";
import patientStore from "../../dataLayer/patients";
import DropInput from "../common/DropInput";
import AddModal from "../modals/AddModal";
import PrescriptionModal from "../modals/PrescriptionModal";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import HelpStore from "../../dataLayer/help";
import { useHistory } from "react-router-dom";
import homeStore from "../../dataLayer/home";
import moment from "moment";

const { confirm } = Modal;
const { Text, Title } = Typography;

var box = {
  backgroundColor: "white",
  margin: 10,
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1",
};
var first = {
  backgroundColor: "#f4f6f9",
  borderRadius: "1em",
  padding: "1em",
  height: "100vh",
};

const editButtonStyle = {
  color: "#ff6347",
  cursor: "pointer",
  fontWeight: "bold",
};

const personalInfoTextColStyle = {
  background: "#fff",
  borderRadius: "2em",
};

const personalInfoTextStyle = {
  padding: "12px",
};

function renderBoxStatusTag(tag) {
  let color = "#1fb988";
  if (tag === "Past") {
    color = "#ff4e24";
  } else if (tag === "Future") {
    color = "#272D5C";
  }
  return (
    <Tag color={color} key={tag}>
      {tag.toUpperCase()}
    </Tag>
  );
}

function formatTime(value) {
  return moment(value).format("MM/DD/YYYY hh:mm a");
}

const boxDetailsTableColumns = [
  {
    title: "Box Start Date",
    dataIndex: "administrationStartDateTime",
    key: "boxid",
    render: (_, boxDetails) =>
      formatTime(boxDetails.administrationStartDateTime),
  },
  {
    title: "Box End Date",
    dataIndex: "administrationEndDateTime",
    key: "boxid",
    render: (_, boxDetails) => formatTime(boxDetails.administrationEndDateTime),
  },
  {
    title: "Box Status",
    dataIndex: "boxStatus",
    key: "boxid",
    render: (_, boxDetails) => renderBoxStatusTag(boxDetails.boxStatus),
  },
];

const Patients = observer(({ props }) => {
  let history = useHistory();

  const [showedit, setshowedit] = useState(false);
  useEffect(() => {
    patientStore.getPatientList();
  }, []);

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // DO SOMETHING HERE
        console.log("Reached bottom");

        patientStore.onScrollEndReached();
      }
    }
  };

  const [sort, setSort] = useState("None");
  const [nav, setNav] = useState([true, false, false]);
  const [show, setShow] = useState(false);
  const [compalert, setcompalert] = useState(false);
  const [selectedComp, setselectedComp] = useState("");
  const [pres, setPres] = useState(false);
  const [navTitle, setnavTitle] = useState([
    "All",
    "Active Patients",
    "InActive Patients",
  ]);
  const handleModal = () => {
    setShow(!show);
  };

  const handlePresModal = () => {
    setPres(!pres);
    patientStore.getPatientRx();
  };
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    patientStore?.handleNav(navTitle[index]);
    setNav(temp);
  };

  function renderConfirmationModal(checked, type) {
    return confirm({
      title: "Are you sure to update this item",
      onOk: async () => {
        await patientStore.setUpdateObj(type, checked);
        await patientStore.updatePatient();
      },
    });
  }

  return (
    <div style={Styles?.main}>
      <AddModal
        show={show}
        title={"Add Patient"}
        handleAdd={async () => {
          await homeStore.handleAddPatient();
          setShow(false);
        }}
        handleClose={handleModal}
      />
      <PrescriptionModal
        show={pres}
        data={patientStore?.patientRx}
        title={"Active Prescriptions"}
        handleClose={handlePresModal}
      />
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Patients</h2>
            </div>
            <div>
              <a onClick={handleModal}>Add Patient</a>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={8}>
          <div className={Styles.topnav}>
            <h6
              className={nav[0] && Styles.selectednav}
              onClick={() => handleClickNav(0)}
            >
              All
            </h6>
          </div>
          <Row>
            <Col span={24}>
              <div style={box}>
                <div>
                  <DropInput
                    value={patientStore.searchString}
                    showSelect={false}
                    placeholder="Enter patients name"
                    onChange={(val) => {
                      // REGEX TO VALIDATE THE SEARCH STRING IF IT HAS ANY SPECIAL CHARACTERS
                      let isStringIncludesSpecialsCharacters = new RegExp(
                        /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
                      );
                      if (!isStringIncludesSpecialsCharacters.test(val)) {
                        patientStore.setSearchString(val);
                      } else {
                        message.warn('Special characters are not allowed')
                      }
                    }}
                  />
                </div>
                <div
                  onScroll={() => onScroll()}
                  ref={listInnerRef}
                  style={{
                    overflowY: "scroll",
                    height: "80vh",
                  }}
                >
                  {patientStore?.patientList?.map((item, index) => {
                    return (
                      <DrListItem
                        item={item}
                        onClick={() => {
                          patientStore.getPatientDataWithCompliance(item._id);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <div className={Styles.rightbox} style={box}>
            {patientStore.selectedPatientDetails._id ? (
              <Row>
                <Col span={24} style={first}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        background: "rgba(35,208,152,1)",
                        color: "#fff",
                        borderRadius: "50%",
                        width: "3em",
                        height: "3em",
                        fontSize: "1.5em",
                        fontWeight: "bold",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0em 1em",
                      }}
                    >
                      <div>
                        {patientStore?.selectedPatientDetails?.name?.[0]}
                      </div>
                    </div>
                    <div>
                      <h4>{patientStore?.selectedPatientDetails?.name}</h4>
                      <div>
                        {patientStore?.selectedPatientDetails?.age},{" "}
                        {patientStore?.selectedPatientDetails?.gender}
                      </div>
                    </div>
                  </div>
                  <div style={{ color: "#000" }} className={Styles.topbuts}>
                    <div
                      onClick={async () => {
                        console.log(
                          "call",
                          patientStore.selectedPatientDetails._id
                        );
                        await HelpStore.setCurrentUserName(
                          patientStore.selectedPatientDetails.name
                        );
                        await HelpStore.setCurrentUser(
                          patientStore.selectedPatientDetails._id
                        );
                        await HelpStore.setCurrentUserType("patient");
                        history.push("/help");
                      }}
                    >
                      <img
                        style={{ margin: "0em .5em", maxWidth: "2em" }}
                        src={Message}
                      ></img>
                    </div>
                  </div>
                  <Row>
                    <Col span={24}>
                      <Row>
                        <Col span={24}>
                          <Title level={3}>PERSONAL INFORMATION</Title>
                        </Col>
                      </Row>
                      <Row
                        gutter={[0, 16]}
                        justify={"space-between"}
                        style={{ margin: "0 10px" }}
                      >
                        <Col span={12} style={personalInfoTextColStyle}>
                          <Space
                            style={personalInfoTextStyle}
                            align={"baseline"}
                          >
                            <img src={Msg}></img>
                            <Text>
                              {patientStore.selectedPatientDetails?.email}
                            </Text>
                            <Button
                              style={editButtonStyle}
                              type="link"
                              onClick={() => {
                                setshowedit(true);
                              }}
                            >
                              Edit
                            </Button>
                          </Space>
                        </Col>
                        <Col span={12} style={personalInfoTextColStyle}>
                          <Space
                            style={personalInfoTextStyle}
                            align={"baseline"}
                          >
                            <img src={Phone}></img>
                            <Text>
                              {patientStore.selectedPatientDetails?.phone}
                            </Text>
                            <Button
                              style={editButtonStyle}
                              type="link"
                              onClick={() => {
                                setshowedit(true);
                              }}
                            >
                              Edit
                            </Button>
                          </Space>
                        </Col>
                        <Col span={24} style={personalInfoTextColStyle}>
                          <Space style={personalInfoTextStyle}>
                            <img src={MapIcon}></img>
                            <Text>
                              {patientStore.selectedPatientDetails?.address}
                            </Text>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space size={"large"} style={personalInfoTextStyle}>
                            <Title level={5}>Whitelisted</Title>
                            <Switch
                              checked={
                                patientStore?.selectedPatientDetails
                                  ?.is_whitelisted
                              }
                              onChange={(checked) =>
                                renderConfirmationModal(
                                  checked,
                                  "is_whitelisted"
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space size={"large"} style={personalInfoTextStyle}>
                            <Title level={5}>OTP Disabled</Title>
                            <Switch
                              checked={
                                patientStore?.selectedPatientDetails
                                  ?.is_otp_disabled
                              }
                              onChange={(checked) =>
                                renderConfirmationModal(
                                  checked,
                                  "is_otp_disabled"
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col span={24}>
                          {patientStore?.selectedPatientDetails?.boxDetails
                            ?.length ? (
                            <Table
                              columns={boxDetailsTableColumns}
                              dataSource={
                                patientStore?.selectedPatientDetails
                                  ?.boxDetails || []
                              }
                              pagination={{
                                total: patientStore?.selectedPatientDetails?.boxDetails?.length,
                                pageSize: 5
                              }}
                            />
                          ) : (
                            <Empty description="No Box" />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <Empty />
              </Row>
            )}
          </div>
        </Col>
      </Row>
      <Modal
        visible={compalert}
        footer={false}
        //  onOk={handleOk}
        onCancel={() => setcompalert(false)}
      >
        <Row style={{ width: "100%" }} align="middle">
          <Col>
            <h2>Create Alert</h2>
          </Col>
          <Col span={24}>
            <p>Alert patient for incomplete compliance</p>
          </Col>

          <Col style={{ marginTop: 100 }} span={24}>
            <div
              style={{
                backgroundColor: "#23D098",
                width: 100,
                height: 40,
                float: "right",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                alignItems: "center",
                borderRadius: 10,
              }}
              onClick={() => {
                patientStore.createAlert(selectedComp);
                setcompalert(false);
              }}
            >
              <p style={{ textAlign: "center", color: "#fff", marginTop: 10 }}>
                Submit
              </p>
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={showedit}
        footer={false}
        //  onOk={handleOk}
        onCancel={() => setshowedit(false)}
      >
        {Object.keys(patientStore.selectedPatientDetails).length > 0 && (
          <Row style={{ width: "100%" }} align="middle">
            <Col span={6} style={{ textAlign: "right" }}>
              <span>Email:</span>
            </Col>
            <Col span={18}>
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  patientStore.setUpdateObj("email", e.target.value);

                  // patientStore.selectedPatientDetails.email = e.target.value
                }}
                className="drp-input"
                value={
                  patientStore.updateObj?.email ||
                  patientStore.updateObj?.email == ""
                    ? patientStore.updateObj?.email
                    : patientStore.selectedPatientDetails?.email
                }
                placeholder="Email"
              ></input>
            </Col>
            <Col span={6} style={{ textAlign: "right" }}>
              <span>Phone:</span>
            </Col>
            <Col span={18}>
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  patientStore.setUpdateObj("phone", e.target.value);
                  // patientStore.selectedPatientDetails.phone = e.target.value
                }}
                className="drp-input"
                placeholder="Phone"
                value={
                  patientStore.updateObj?.phone ||
                  patientStore.updateObj?.phone == ""
                    ? patientStore.updateObj?.phone
                    : patientStore.selectedPatientDetails?.phone
                }
              ></input>
            </Col>
            {/* <Col span={6} style={{ textAlign: 'right' }}><span>Address Line:</span></Col> */}
            {/* <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              patientStore.selectedPatientDetails.address.AddressLine = e.target.value
            }}
            className="drp-input" placeholder="Address Line" value={patientStore.selectedPatientDetails?.address?.AddressLine || ''}></input></Col> */}
            {/* <Col span={6} style={{ textAlign: 'right' }}><span>City:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.City = e.target.value
          }}
          className="drp-input" placeholder="City" value={patientsStore.comp3Data?.address?.City || ''}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>State Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.StateCode = e.target.value
          }}
          className="drp-input" placeholder="State Code" value={patientsStore.comp3Data?.address?.StateCode || ''}></input></Col>
        <Col span={6} style={{ textAlign: 'right' }}><span>Zip Code:</span></Col>
        <Col span={18}><input
          onChange={(e) => {
            console.log(e.target.value)
            patientsStore.comp3Data.address.ZipCode = e.target.value
          }}
          className="drp-input" placeholder="Zip Code" value={patientsStore.comp3Data?.address?.ZipCode || ''}></input></Col> */}
            <Col span={24}>
              <Button
                onClick={async () => {
                  var obj = toJS(patientStore.selectedPatientDetails);
                  console.log(obj);
                  patientStore.updatePatient();
                  setshowedit(false);
                  // var res = await dbstore.updatePatientDetails(obj)
                  // if (res) {
                  //   message.success("Update Successfull!")
                  // } else {
                  //   message.error('Oops! something went wrong')
                  // }
                }}
                className="drp-btn-green"
                style={{
                  display: "block",
                  width: 125,
                  marginTop: 20,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
});
export default Patients;
