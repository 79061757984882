
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import patientStore from '../../dataLayer/patients';
import Styles from '../Styles/HomeStyle.module.css';
import Thermometer from '../../assets/images/Tempreature.png';
import Pulse from '../../assets/images/pulse-oximeter.png';
import Weight from '../../assets/images/weight-scale.png';
import Glucose from '../../assets/images/outline.png';
import Blood from '../../assets/images/blood-pressure.png';
import _ from 'lodash';
import VitalChart from '../common/VitalChart';
import { observer } from 'mobx-react';

var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  borderRadius: 30,
  padding: 40,
  boxShadow: "0px 0px 5px 5px #e1e1e1"
};
const PatientVitalDetails = observer(({ props }) => {
  const patDetails = patientStore?.selectedPatientDetails;
  // const { iden } = useParams();
  const vitalArr = ['bloodPressure', 'bloodGlucose', 'temperature', 'weight', 'oxygenLevels']
  useEffect(() => {
    patientStore.getVitalRecordsMega(patientStore.selectedVital);

  }, [])
  const iden = _.findIndex(vitalArr, (o) => o === patientStore.selectedVital)

  console.log(iden, 'iden')
  const history = useHistory()
  const [data, setData] = useState([
    {
      vital: patDetails?.patientVitals?.bloodPressure,
      bgcolor: "linear-gradient(22.25deg, #FE8B6E 8.33%, #FF6773 83.06%)",
      image: Blood
    },
    {
      vital: patDetails?.patientVitals?.bloodGlucose,
      bgcolor: "linear-gradient(40.95deg, #9F64A8 6.44%, #6F66D1 99.45%)",
      image: Glucose
    },
    {
      vital: patDetails?.patientVitals?.temperature,
      bgcolor: "linear-gradient(40.95deg, #61C2D2 6.44%, #0B82BA 99.45%)",
      image: Thermometer
    },
    {
      vital: patDetails?.patientVitals?.weight,
      bgcolor: "linear-gradient(40.95deg, #9692E9 6.44%, #574FFA 99.45%)",
      image: Weight
    },
    {
      vital: patDetails?.patientVitals?.oxygenLevels,
      bgcolor: "linear-gradient(40.95deg, #FFB75E 6.44%, #ED8F03 99.45%)",
      image: Pulse
    },
  ]);
  console.log(iden);


  return (
    <>
      <Row gutter={[32, 32]} style={{ padding: 20 }}>
        <Col span={24}>
          <div className={Styles?.box} style={{ background: data[iden]?.bgcolor }}>
            <div>
              <h1>
                <span
                  onClick={() => { history.goBack() }}
                  style={{ color: "#fff", cursor: 'pointer' }}>&#8592;</span>

              </h1>
              <img src={data[iden]?.image}></img>
              <h5>{data[iden]?.vital?.title}</h5>
            </div>
            <div>
              <h5>{iden != 0 ? data[iden]?.vital?.amount : data[iden]?.vital?.systolic + " | " + data[iden]?.vital?.diastolic}</h5>
              <div>{data[iden]?.vital?.unit}</div>
            </div>
          </div>
        </Col>
        <Col span={14}>
          <div style={box}>
            {patientStore.selectedVital === 'bloodPressure' ?
              <>
                <div style={{ flexDirection: 'column', }}>
                  <div
                    onClick={() => {
                      patientStore.setSelectedBp('systolic');
                    }}
                    style={{ backgroundColor: '#fff', cursor: 'pointer', border: patientStore.selectedBp == 'systolic' ? "1px solid black" : 0, padding: 10, borderRadius: 10, textAlign: 'center', width: 100, display: 'inline-block' }}>
                    Systolic
                  </div>
                  <div
                    onClick={() => {
                      patientStore.setSelectedBp('diastolic');
                    }}
                    style={{ backgroundColor: '#fff', cursor: 'pointer', border: patientStore.selectedBp == 'diastolic' ? "1px solid black" : 0, padding: 10, borderRadius: 10, textAlign: 'center', width: 100, display: 'inline-block' }}>
                    Diastolic
                  </div>
                </div>
                <VitalChart data={patientStore.selectedPatientDetails.patientVitals[patientStore.selectedVital].chartData[patientStore.selectedBp]} />

              </> :
              <VitalChart data={patientStore.selectedPatientDetails.patientVitals[patientStore.selectedVital].chartData} />

            }
          </div>
        </Col>
        <Col span={10}>
          <div style={box}>
            <div className={Styles.header}>
              <div>
                Records ({data[iden]?.vital?.unit})
              </div>
              <div>
                Date
              </div>

            </div>
            <div style={{ overflowY: "scroll", height: "60vh" }}>
              {
                patientStore.vitalRecordsMega.map((item, index) => (
                  <div className={Styles.entries}>
                    {!item?.value ? <div>
                      {item?.systolic}/{item?.diastolic}
                    </div> : <div>
                      {item?.value}
                    </div>}

                    <div>
                      {item?.date}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default PatientVitalDetails;