
import {

    makeAutoObservable,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
import { message } from "antd";
class Store {
    constructor() {
        makeAutoObservable(this, {

        });
    }

    phDetails = {
        prxPort: 0,
        rxSafePort: 0,
        prxStatus: 'active',
        rxSafeStatus: 'active'

    }
    originalPhDetails = {}
    editable = false;

    getPhDetails = async (phid) => {
        if (!phid) {
            phid = dbstore.pharmacyId
        }
        dbstore.checkSession()

        var path = '/admin/pharmacy/details';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?phid=${phid}`,
            options,

        );
        res = await res.json();
        Object.assign(this.phDetails, res)
        Object.assign(this.originalPhDetails, res)
        console.log(res);

    }

    setPrxPort = (val) => {
        if (isNaN(val)) {
            message.error('Only numbers allowed')
            return
        }
        this.phDetails.prxPort = val
    }

    setRxSafePort = (val) => {
        if (isNaN(val)) {
            message.error('Only numbers allowed')
            return
        }
        this.phDetails.rxSafePort = val
    }



    submit = async (phid) => {
        dbstore.checkSession()

        console.log('clicked')
        if (this.editable) {
            if (!phid) {
                phid = dbstore.pharmacyId
            }
            var obj = {
                "phid": phid,
                "prxPort": this.phDetails.prxPort,
                "rxSafePort": this.phDetails.rxSafePort
            }

            if (obj.prxPort == this.originalPhDetails.prxPort) {
                delete obj.prxPort
            }
            if (obj.rxSafePort == this.originalPhDetails.rxSafePort) {
                delete obj.rxSafePort
            }


            if (obj.prxPort == 0) {
                delete obj.prxPort
            }
            if (obj.rxSafePort == 0) {
                delete obj.rxSafePort
            }




            var path = '/admin/update/pharmacy/details';
            var options = {
                method: "put",
                headers: { "Content-Type": "application/json" },

                credentials: 'include',
                body: JSON.stringify(obj)
            }
            options['cache'] = "no-cache"

            var res = await fetch(
                `${dbstore.endpoint}${path}`,
                options,

            );
            console.log(res)
            // res = await res.json();
            this.editable = false;
            this.getPhDetails(phid)
        } else {
            this.editable = true
        }
    }

}

const PhSettingsStore = new Store();
export default PhSettingsStore;