import { Col, Row, Layout } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart as ChartJS } from 'chart.js/auto'

const VitalChart = observer(({ data }) => {
  const rand = () => Math.round(Math.random() * 20 - 10);
  var randoms = () => [...Array(12)].map(() => Math.floor(Math.random() * 20));
  var vitalColors = {
    bloodPressure: '#FF6773',
    bloodGlucose: '#7066D1',
    temperature: '#61C2D2',
    weight: '#574FFA',
    oxygenLevels: '#ED8F03',
  }
  if (!data) {
    data = {
      name: "bloodPressure",
      // labels: randoms(),
      // data: randoms(),
      labels: [],
      data: []
    };
  }
  var c1 = `${vitalColors[data.name]}FF`
  var c2 = `${vitalColors[data.name]}00`
  var reminderlist = [];
  var takenlist = [];


  const graphdata = {
    labels: data.labels,
    datasets: [
      {
        label: data.name,
        data: data.data,
        borderColor: vitalColors[data.name],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          var gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, c1);
          gradient.addColorStop(1, c2);
          return gradient;
        },
        fill: true,
        pointBackgroundColor: vitalColors[data.name],
        pointRadius: 3,
        type: "line",
        order: 1,
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
    },
    //responsive: true,

    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        grid: { lineWidth: 0.2 },
      },
    },
    maintainAspectRation: false,
  };

  return (
    <Bar
      data={graphdata}
      // width={100}
      height={150}
      options={options}
    />
  );
});
export default VitalChart;
