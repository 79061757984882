
import {

    makeAutoObservable, toJS,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
class Store {

    constructor() {
        makeAutoObservable(this, {

        });
    }

    getHome = async () => {
        dbstore.checkSession()

        dbstore.setLoading(true)
        var path = '/admin/home';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            options,

        );
        res = await res.json();
        console.log(res);
        this.homeData.Clinics = res.pharmacies;
        this.homeData.Doctors = res.doctors;
        this.homeData.Patients = res.patients;
        this.homeData.Technicians = res.technicians

        var recents = res.recents;
        var patientList = [];
        var requestList = []
        for (var i = 0; i < recents.length; i++) {
            var recent = recents[i];
            var patObj = {
                id: recent.patObjId,
                name: recent.patientName,
                age: moment().diff(recent.patientDob, 'years'),
                gender: recent.patientGender,
                date: moment(recent.filldate).format('DD MMM YYYY'),
                time: moment(recent.filldate).format('hh:mm a'),
            }

            var presObj = {
                id: recent._id,
                prescriptionName: recent.name,
                direction: recent.directions,
                date: moment(recent.filldate).format('DD MMM YYYY'),
                time: moment(recent.filldate).format('hh:mm a'),
            }
            patientList.push(patObj);
            requestList.push(presObj);
        }

        console.log(patientList, requestList);
        this.patientList = patientList;
        this.requestList = requestList;

        dbstore.setLoading(false)
    }

    homeData = {
        Clinics: '-',
        Doctors: '-',
        Patients: '-',
        Technicians: '-'
    }
    patientList = [

    ]
    requestList = [

    ]

    inputData = {
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: new Date(),
        phone: '',
        email: ''
    }
    setInputData = (key, val) => {
        this.inputData[key] = val
        console.log(toJS(this.inputData))
    }
    getHomeData() {
        console.log('get home data fired');
    }
    handleAddDoctor() {
        console.log('Add doctor');
    }
    handleAddPatient = async () => {
        // console.log('Add patient', this.inputData);
        dbstore.checkSession()

        dbstore.loading = true
        var patientsData = this.inputData
        var requiredFields = ["firstName", "lastName", 'dateOfBirth', 'gender']
        for (var i = 0; i < requiredFields.length; i++) {
            var temp = requiredFields[i]
            if (!patientsData[temp]) {
                alert(`Error ${temp} is needed`)
                return
            }

        }
        console.log(moment(patientsData.dateOfBirth).isValid())
        if (!moment(patientsData.dateOfBirth).isValid()) {
            alert('Invalid date of birth, give mm/dd/yyyy format')
            return
        }

        var raw = toJS(patientsData)
        var path = '/admin/patients';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );

        res = await res.json();


        if (res.message == 'success') {
            alert('Patient added successfully')
            this.emptyInputDate()
        }
        dbstore.loading = false

        console.log(res, 'res')

    }

    emptyInputDate = () => {
        Object.assign(this.inputData, {
            firstName: '',
            lastName: '',
            gender: '',
            dateOfBirth: new Date(),
            phone: '',
            email: ''
        })
    }
    handleAddTechnician() {
        console.log('Add Technician');
    }
}
const homeStore = new Store();
export default homeStore;