import { Col, Row, Layout, Input, Empty, Modal, Button } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import { Image, DropdownButton, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import Technician from '../../assets/images/pharmacist.png';
import Clinic from '../../assets/images/XMLID_412_.png';
import Patient from '../../assets/images/patientIco.png';
import Doctor from '../../assets/images/Path 37556.png';
import ListEle from '../common/ListEle';
import Styles from '../Styles/HomeStyle.module.css';
import DrListItem from '../common/DrListItem';
import Msg from '../../assets/animations/drp1/Icon material-email.png';
import Phone from '../../assets/animations/drp1/Icon awesome-phone-alt.png';
import Person from '../../assets/animations/drp1/Icon awesome-map-marked-alt.png';
import Video from '../../assets/animations/drp1/Group 70.png';
import Audio from '../../assets/animations/drp1/Group 71.png';
import Message from '../../assets/animations/drp1/Icon ionic-ios-chatbubbles.png';
import Home from '../../assets/animations/drp1/Icon material-store-mall-directory.png';
import technicianStore from '../../dataLayer/technician';
import DropInput from '../common/DropInput';
import AddModal from '../modals/AddModal';
import { observer } from 'mobx-react';
import HelpStore from '../../dataLayer/help';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import patientStore from '../../dataLayer/patients';

var box = {
  // minHeight: '80vh',
  backgroundColor: "white",
  margin: 10,
  // width:"fit-content",
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};

var first = {
  backgroundColor: "#f4f6f9",
  borderRadius: "1em",
  padding: "1em"
}
const Technicians = observer(({ props }) => {
  let history = useHistory();

  useEffect(() => {
    technicianStore.getTechnicianList();

  }, [])


  const [showedit, setshowedit] = useState(false)


  const listInnerRef = useRef();
  const ptListInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom');

        technicianStore.onScrollEndReached()
      }
    }
  };
  const onPtScroll = () => {
    if (ptListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = ptListInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom');

        technicianStore.onPtScrollEndReached()
      }
    }
  };
  const docDetails = technicianStore?.selectedTechnicianDetails;
  const [sort, setSort] = useState('None');
  const [show, setShow] = useState(false);
  const [nav, setNav] = useState([true, false, false]);
  const [navTitle, setnavTitle] = useState(["All", "Active", "In Active"])
  const handleModal = () => {
    setShow(!show);
  }
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    technicianStore?.handleNav(navTitle[index]);
    setNav(temp);
  }
  return (
    <div style={Styles?.main}>
      <AddModal
        show={show}
        title={"Add Technician"}
        handleAdd={technicianStore?.handleAddTechnician}
        handleClose={handleModal}
      />
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Technicians</h2>
            </div>
            <div>
              <a onClick={handleModal}>Add Technician</a>
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className={Styles.topnav}>
            <h6 className={nav[0] && Styles.selectednav} onClick={() => handleClickNav(0)}>All</h6>
            {/* <h6 className={nav[1] && Styles.selectednav} onClick={() => handleClickNav(1)}>Active</h6>
            <h6 className={nav[2] && Styles.selectednav} onClick={() => handleClickNav(2)}>In Active</h6> */}
          </div>
          <Row>
            <Col span={24}>
              <div style={box}>
                <div>
                  <DropInput value={technicianStore.searchString} showSelect={false} placeholder="Enter technicians name" onChange={(val) => {
                    technicianStore.setSearchString(val);
                  }} />
                </div>
                <div onScroll={() => onScroll()} ref={listInnerRef} style={{ height: "50vh", overflowY: "scroll" }}>
                  {
                    technicianStore?.technicianList?.map((item, index) => {
                      return (
                        <DrListItem item={item} onClick={() => {
                          technicianStore.selectTechnician(item._id)
                        }} />
                      );
                    })
                  }
                </div>
              </div>
            </Col>
          </Row>

        </Col>
        <Col span={16}>
          <div className={Styles.rightbox} style={{ ...box, minHeight: '70vh' }}>
            {technicianStore.selectedTechnicianDetails._id ? <Row>
              <Col className={Styles.leftist} span={12} style={first}>
                <div style={{ display: "flex", flexDirection: "row", }}>
                  <div style={{ background: "rgba(35,208,152,1)", color: "#fff", borderRadius: "50%", width: "3em", height: "3em", fontSize: "1.5em", fontWeight: "bold", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", margin: "0em 1em" }}><div>{docDetails?.name?.[0]}</div></div>
                  <div>
                    <h4>{docDetails?.name}</h4>
                    <div>{docDetails?.age},{docDetails?.gender}</div>
                  </div>
                </div>
                <div className={Styles.fields}>
                  <div>
                    <div>
                      <img src={Msg}></img>
                      <div>{docDetails?.email}</div>
                    </div>
                    <div onClick={() => {

                      setshowedit(true)

                    }} className={Styles.edit}>
                      Edit
                    </div>
                  </div>
                  <div>
                    <div>
                      <img src={Phone}></img>
                      <div>{docDetails?.phone}</div>

                    </div>
                    <div onClick={() => {

                      setshowedit(true)

                    }} className={Styles.edit}>
                      Edit
                    </div>
                  </div>
                  {/* <div>
                    <div>
                      <img src={Person}></img>
                      <div>{docDetails?.address}</div>
                    </div>
                  </div> */}
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h5>Assigned Doctor</h5>
                    <a style={{ background: "rgba(35,208,152,1)", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>Edit</a>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", background: "#fff", borderRadius: "1em", border: "1px solid #000", padding: "1em", margin: "1em" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                        <div style={{ padding: "1em", borderRadius: "2em", background: "#e3e3e3" }}></div>
                        <div style={{ margin: "0em 1em" }}>{docDetails?.AssignedDoctor?.name}</div>
                      </div>
                      <div>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Audio}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Message}></img>
                        <img style={{ maxWidth: "2em", margin: "0em .5em" }} src={Video}></img>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <h5>Home Clinic</h5>
                  </div>
                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center", background: "#fff", borderRadius: "1em", padding: "1em", margin: "1em" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center" }}>
                        <img style={{ margin: "0em 1em" }} src={Home}></img>
                        <div>{technicianStore.selectedTechnicianDetails.homeClinic}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: "1em" }}>
                    {/* <div>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Audio}></img>
                    </div> */}
                    <div onClick={async () => {
                      console.log("call", technicianStore.selectedTechnicianDetails._id);
                      await HelpStore.setCurrentUserName(technicianStore.selectedTechnicianDetails.name);
                      await HelpStore.setCurrentUser(technicianStore.selectedTechnicianDetails._id);
                      await HelpStore.setCurrentUserType('technician')
                      history.push('/help')

                    }}>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Message}></img>
                    </div>
                    {/* <div>
                      <img style={{ margin: "0em .5em", maxWidth: "5em" }} src={Video}></img>
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col className={Styles.leftist} span={12}>
                <div style={{ padding: "1em" }}>
                  <h5>Patients Alloted</h5>
                  <div onScroll={() => onPtScroll()} ref={ptListInnerRef} style={{ height: "40vh", overflowY: "scroll" }}>
                    {
                      technicianStore?.patientList?.map((item, index) => {
                        return (
                          <DrListItem item={item} onClick={async () => {
                            await patientStore.setSearchString(item.name);
                            patientStore.selectPatient(item._id)
                            history.push('/patients')
                          }} />
                        );
                      })
                    }
                  </div>
                </div>
                <div className={Styles.viewsection} style={{ padding: "1em" }}>

                  <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5em 0em" }}>
                    <h6>Daily Reports</h6>
                    <a style={{ background: "rgba(35,208,152,1)", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>View</a>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5em 0em" }}>
                    <h6>Time log</h6>
                    <a style={{ background: "rgba(35,208,152,1)", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>View</a>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5em 0em" }}>
                    <h6>Make inactive</h6>
                    <a
                      onClick={() => {
                        technicianStore.updateActiveStatus()
                      }}
                      style={{ background: technicianStore.selectedTechnicianDetails?.status == 'active' ? "red" : "green", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>{technicianStore.selectedTechnicianDetails?.status == 'active' ? "Inactivate" : "Activate"}</a>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", margin: "0.5em 0em" }}>

                    <a onClick={() => {
                      console.log('clicked')
                      technicianStore.setTechnicianToPharmacy(technicianStore.selectedTechnicianDetails._id);
                    }} style={{ background: "red", color: "#fff", fontWeight: "bold", padding: ".5em 1em", borderRadius: "2em" }}>Set as default technician</a>
                  </div>


                </div>
              </Col>
            </Row> : <Row style={{ justifyContent: 'center', alignItems: 'center', height: "70vh", }}><Empty /></Row>}
          </div>
        </Col>
      </Row>

      <Modal
        visible={showedit}
        footer={false}
        //  onOk={handleOk} 
        onCancel={() => setshowedit(false)}
      >
        {Object.keys(technicianStore.selectedTechnicianDetails).length > 0 && <Row style={{ width: '100%' }} align="middle">
          <Col span={6} style={{ textAlign: 'right' }}><span>Email:</span></Col>
          <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              technicianStore.setUpdateObj('email', e.target.value)

              // technicianStore.selectedTechnicianDetails.email = e.target.value
            }}

            className="drp-input" value={technicianStore.updateObj?.email || technicianStore.updateObj?.email == '' ? technicianStore.updateObj?.email : technicianStore.selectedTechnicianDetails?.email} placeholder="Email"></input></Col>
          <Col span={6} style={{ textAlign: 'right' }}><span>Phone:</span></Col>
          <Col span={18}><input
            onChange={(e) => {
              console.log(e.target.value)
              technicianStore.setUpdateObj('phone', e.target.value)

              // technicianStore.selectedTechnicianDetails.phone = e.target.value
            }}
            className="drp-input" placeholder="Phone" value={technicianStore.updateObj?.phone || technicianStore.updateObj?.phone == '' ? technicianStore.updateObj?.phone : technicianStore.selectedTechnicianDetails?.phone}></input></Col>

          <Col span={24} >
            <Button
              onClick={async () => {
                var obj = toJS(technicianStore.selectedTechnicianDetails);
                console.log(obj)
                setshowedit(false)
                technicianStore.updateTechnician()
                // var res = await dbstore.updatePatientDetails(obj)
                // if (res) {
                //   message.success("Update Successfull!")
                // } else {
                //   message.error('Oops! something went wrong')
                // }
              }}
              className="drp-btn-green" style={{ display: 'block', width: 125, marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>Submit</Button>
          </Col>

        </Row>}
      </Modal>


    </div>
  );
});
export default Technicians;