
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react';
// import BloodPressure from '../../assets/images'
import Thermometer from '../../assets/images/Tempreature.png';
import Pulse from '../../assets/images/pulse-oximeter.png';
import Weight from '../../assets/images/weight-scale.png';
import Glucose from '../../assets/images/outline.png';
import Blood from '../../assets/images/blood-pressure.png';
import Styles from '../Styles/HomeStyle.module.css';
import VitalCard from '../common/VitalCard';
import VitalChart from '../common/VitalChart';
import { observer } from 'mobx-react';
import patientStore from '../../dataLayer/patients';
import { NavLink } from 'react-router-dom';
var box = {
  minHeight: 100,
  // backgroundColor: "white",
  // margin: 10,
  padding: 30,
  marginRight: 40,
  marginLeft: 40,
  // marginRight: 30,
};
const PatientVitals = observer(({ props }) => {

  useEffect(() => {
    patientStore.getPatientVitals();
    patientStore.getVitalRecordsMini(patientStore.selectedVital);

    console.log(patientStore.selectedPatientDetails._id, 'patientStore.selectedPatientDetails._id')
  }, [])
  const patDetails = patientStore?.selectedPatientDetails;
  const [index, setIndex] = useState([
    false,
    false,
    false,
    false,
    false
  ]);
  const [inn, setInn] = useState(0);
  var data = [
    {
      vital: patDetails?.patientVitals?.bloodPressure,
    },
    {
      vital: patDetails?.patientVitals?.bloodGlucose,
    },
    {
      vital: patDetails?.patientVitals?.temperature,
    },
    {
      vital: patDetails?.patientVitals?.weight,
    },
    {
      vital: patDetails?.patientVitals?.oxygenLevels,
    },
  ]
  const [record, setRecord] = useState(data[0]?.vital?.record);
  const vitalArr = ['bloodPressure', 'bloodGlucose', 'temperature', 'weight', 'oxygenLevels']
  const handleIndex = (index) => {
    patientStore.setSelectedVital(vitalArr[index]);
    patientStore.getVitalRecordsMini(vitalArr[index]);
    var temp = [false, false, false, false, false];
    temp[index] = true;
    setIndex(temp);
    setInn(index);
    setRecord(data[index]?.vital?.record);
  }
  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box}>
            <div className={Styles.mainblock}>
              <div>
                <VitalCard
                  image={Blood}
                  title={"Blood Pressure"}
                  systolic={Math.round(patDetails?.patientVitals?.bloodPressure?.systolic * 100 || 0) / 100}
                  dystolic={Math.round(patDetails?.patientVitals?.bloodPressure?.diastolic * 100 || 0) / 100}
                  unit={patDetails?.patientVitals?.bloodPressure?.unit}
                  comment={patDetails?.patientVitals?.bloodPressure?.comment}
                  bgcolor={"linear-gradient(22.25deg, #FE8B6E 8.33%, #FF6773 83.06%)"}
                  selected={index[0]}
                  onClick={() => handleIndex(0)}
                  iden={0}
                />
              </div>
              <div>
                <VitalCard
                  image={Glucose}
                  title={"Blood Glucose"}
                  amount={Math.round(patDetails?.patientVitals?.bloodGlucose?.value * 100 || 0) / 100}
                  unit={patDetails?.patientVitals?.bloodGlucose?.unit}
                  comment={patDetails?.patientVitals?.bloodGlucose?.comment}
                  bgcolor={"linear-gradient(40.95deg, #9F64A8 6.44%, #6F66D1 99.45%)"}
                  selected={index[1]}
                  onClick={() => handleIndex(1)}
                  iden={1}
                />
              </div>
              <div>
                <VitalCard
                  image={Thermometer}
                  title={"Tempreature"}
                  amount={Math.round(patDetails?.patientVitals?.temperature?.value * 100 || 0) / 100}
                  unit={patDetails?.patientVitals?.temperature?.unit}
                  comment={patDetails?.patientVitals?.temperature?.comment}
                  bgcolor={"linear-gradient(40.95deg, #61C2D2 6.44%, #0B82BA 99.45%)"}
                  selected={index[2]}
                  onClick={() => handleIndex(2)}
                  iden={2}
                />
              </div>
              <div>
                <VitalCard
                  image={Weight}
                  title={"Weight"}
                  amount={Math.round(patDetails?.patientVitals?.weight?.value * 100 || 0) / 100}
                  unit={patDetails?.patientVitals?.weight?.unit}
                  comment={patDetails?.patientVitals?.weight?.comment}
                  bgcolor={"linear-gradient(40.95deg, #9692E9 6.44%, #574FFA 99.45%)"}
                  selected={index[3]}
                  onClick={() => handleIndex(3)}
                  iden={3}
                />
              </div>
              <div>
                <VitalCard
                  image={Pulse}
                  title={"Oxygen Levels"}
                  amount={Math.round(patDetails?.patientVitals?.oxygenLevels?.value * 100) / 100}
                  unit={patDetails?.patientVitals?.oxygenLevels?.unit}
                  comment={patDetails?.patientVitals?.oxygenLevels?.comment}
                  bgcolor={"linear-gradient(40.95deg, #FFB75E 6.44%, #ED8F03 99.45%)"}
                  selected={index[4]}
                  onClick={() => handleIndex(4)}
                  iden={4}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col span={14}>
          <div style={box} className={Styles.recordsec}>
            {patientStore.selectedVital === 'bloodPressure' ?
              <>
                <div style={{ flexDirection: 'column', }}>
                  <div
                    onClick={() => {
                      patientStore.setSelectedBp('systolic');
                    }}
                    style={{ backgroundColor: '#fff', cursor: 'pointer', border: patientStore.selectedBp == 'systolic' ? "1px solid black" : 0, padding: 10, borderRadius: 10, textAlign: 'center', width: 100, display: 'inline-block' }}>
                    Systolic
                  </div>
                  <div
                    onClick={() => {
                      patientStore.setSelectedBp('diastolic');
                    }}
                    style={{ backgroundColor: '#fff', cursor: 'pointer', border: patientStore.selectedBp == 'diastolic' ? "1px solid black" : 0, padding: 10, borderRadius: 10, textAlign: 'center', width: 100, display: 'inline-block' }}>
                    Diastolic
                  </div>
                </div>
                <VitalChart data={patientStore.selectedPatientDetails.patientVitals[patientStore.selectedVital].chartData[patientStore.selectedBp]} />

              </> :
              <VitalChart data={patientStore.selectedPatientDetails.patientVitals[patientStore.selectedVital].chartData} />

            }
          </div>
        </Col>
        <Col span={10}>
          <div style={box} className={Styles.recordsec}>
            <div className={Styles.header}>
              <div>
                Recent Records
              </div>
              <div>
                Date
              </div>

            </div>
            {
              patientStore.vitalRecordsMini.map((item, index) => (
                <div className={Styles.entries}>
                  {!item?.value ? <div>
                    {item?.systolic}/{item?.diastolic}
                  </div> : <div>
                    {item?.value}
                  </div>}
                  <div>
                    {item?.date}
                  </div>
                </div>
              ))
            }
            <div style={{ textAlign: "center", padding: "1em" }}>
              <NavLink to={`/patientVitalDetails`} style={{ color: "#9b9b9b", textAlign: "center" }}>
                {"View more >"}
              </NavLink>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default PatientVitals;