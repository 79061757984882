import React from "react";
import Styles from '../Styles/HomeStyle.module.css';
function VitalCard(props) {
  return (
    <>
      <div className={Styles.cards} onClick={props?.onClick}>
        <div className={props?.selected && Styles.selectedCard}>
          <div style={{ background: props?.bgcolor, height: 350 }} >
            <div>
              <img src={props?.image}></img>

              <div><a style={{ color: "#fff" }} href={`/patientVitalDetails/${props?.iden}`}>View Record</a></div>
            </div>
            <div>
              {props?.title}
            </div>
            <div>
              {!props?.amount ? (props?.systolic ? props?.systolic + " | " + props?.dystolic : 0) : props?.amount}
            </div>
            <div>
              {props?.unit}
            </div>
            <div>
              {props?.comment}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default VitalCard;