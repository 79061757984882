
import { Col, Row, Layout } from 'antd'
import { useEffect, useState } from 'react'
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import patientStore from '../../dataLayer/patients';
import { observer } from 'mobx-react';



var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: "10em 5em",
  overflow: "scroll",
  padding: 30,
  // paddingRight:0,
  borderRadius: 50,
  maxWidth: "100%",
  // margin:"10em auto"
};
const PatientCalendar = observer(({ props }) => {
  const [calendarState, setcalendarState] = useState(null)
  useEffect(() => {
    patientStore.getCalendarData()
    // document.addEventListener('DOMContentLoaded', function () {
    let calendarEl = document.getElementById('calendar');
    let calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin],
      // plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek'
      },
      events: patientStore.calendarEvents,

    });
    setcalendarState(calendar)
    calendar.render();
    // });
  }, []);

  useEffect(() => {
    if (calendarState) {
      calendarState.setOption('events', patientStore.calendarEvents)
      calendarState.render()
    }
  }, [patientStore.calendarEvents])

  return (
    <>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <div style={box} id="calendar"></div>
        </Col>
      </Row>
    </>
  );
});
export default PatientCalendar;