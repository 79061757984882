import { Col, Row, Layout, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import Styles from '../../Styles/HomeStyle.module.css';
import DrListItem from '../../common/DrListItem';
import DropInput from '../../common/DropInput';
import reportStore from '../../../dataLayer/reports';
import { observer } from 'mobx-react';
import ComplianceChart from '../../common/ComplianceChart';

var boxsec = {
  backgroundColor: "white",
  margin: 10,
  padding: "2em 4em",
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};
var box = {
  height: '60vh',
  backgroundColor: "white",
  margin: 10,
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1",
  overflow: 'scroll'
};

const Reports = observer(({ props }) => {
  useEffect(() => {
    reportStore.getDoctorList();
    reportStore.getTechnicianList();
  }, [])
  const [sort, setSort] = useState('None');
  const [nav, setNav] = useState([true, false, false]);
  const [navTitle, setnavTitle] = useState(["Doctors", "Technicians"]);
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    reportStore?.handleNav(navTitle[index]);
    setNav(temp);
  }
  const [type, settype] = useState('doctor');
  return (
    <div style={Styles?.main}>
      <Row gutter={[32, 32]} style={{ padding: "0em 0em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Reports</h2>
            </div>
          </div>
        </Col>

        <Col span={8}>
          <div className={Styles.topnav}>
            <h6 className={nav[0] && Styles.selectednav} onClick={() => { handleClickNav(0); settype('doctor') }}>Doctors</h6>
            <h6 className={nav[1] && Styles.selectednav} onClick={() => { handleClickNav(1); settype('technician') }}>Technicians</h6>
          </div>
          <Row>
            <Col span={24}>
              <div style={box}>

                {
                  type == 'doctor' &&
                  <>
                    <div>
                      <DropInput value={reportStore.docSearchString} showSelect={false} placeholder="Enter doctors name" onChange={(val) => {
                        reportStore.setDocSearchString(val);
                      }} />
                    </div>
                    <div>
                      {reportStore?.doctorList?.map((item, index) => {
                        return (
                          <DrListItem item={item} onClick={() => {
                            reportStore.getReports(type, item._id)
                          }} />
                        );
                      })}
                    </div></>
                }
                {
                  type == 'technician' &&
                  <>
                    <div>
                      <DropInput value={reportStore.searchString} showSelect={false} placeholder="Enter technicians name" onChange={(val) => {
                        reportStore.setSearchString(val);
                      }} />
                    </div>
                    <div>
                      {reportStore?.technicianList?.map((item, index) => {
                        return (
                          <DrListItem item={item} onClick={() => {
                            reportStore.getReports(type, item._id)
                          }} />
                        );
                      })}
                    </div></>
                }
              </div>
            </Col>
          </Row>

        </Col>
        <Col span={16}>

          <div className={Styles.rightbox} style={boxsec}>
            <div className={Styles.topblue}>
              <a href='#'>Download Report</a>
              <div>
                <select>
                  <option>
                    Last 30 days
                  </option>
                </select>
              </div>
            </div>
            <div className={Styles.bluebox}>
              <div style={{ margin: 10 }}>
                <h1 style={{ textAlign: 'center' }}>{reportStore?.reportDetail?.PatientsVisited}</h1>
                <h5 style={{ textAlign: 'center' }}>Patients Visited</h5>
              </div>
              <div style={{ margin: 10 }}>
                <h1 style={{ textAlign: 'center' }}>{reportStore?.reportDetail?.PrescriptionsFiled}</h1>
                <h5 style={{ textAlign: 'center' }}>Prescriptions filed</h5>
              </div>
              <div style={{ margin: 10 }}>
                <h1 style={{ textAlign: 'center' }}>{reportStore?.reportDetail?.RefillsCompleted}</h1>
                <h5 style={{ textAlign: 'center' }}>Refills Completed</h5>
              </div>
              <div style={{ margin: 10 }}>
                <h1 style={{ textAlign: 'center' }}>
                  {reportStore?.reportDetail?.PAsCompleted}
                </h1>
                <h5 style={{ textAlign: 'center' }}>PAs Completed</h5>
              </div>
            </div>
            <div style={{ padding: "1em", border: "1px solid #cfcfcf", borderRadius: "1em", margin: "1em 0em" }}>
              {/* <VitalChart /> */}
              <ComplianceChart data={reportStore.reportData} />
            </div>

          </div>
        </Col>
      </Row>
    </div>
  );
});
export default Reports;