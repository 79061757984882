import React from "react";
import { Row } from "antd";
import Styles from "../Styles/HomeStyle.module.css";
import { observer } from "mobx-react";
import moment from "moment";

const liststyle = {
  background: "#f0f0f7",
  padding: "1em",
  borderRadius: "1em",
  marginBottom: "1em",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  cursor: "pointer",
};
const box = {
  paddingTop: "auto",
  paddingBottom: "auto",
};

const DrListItem = observer((props) => {
  console.log(",sjfbnreov", JSON.stringify(props));

  const age = moment().diff(props?.item?.dob, "years", false);
  const gender = props?.item?.gender;
  const ageAndGenderString =
    age && gender ? `${age}, ${gender}` : !age ? gender : !gender ? age : "";

  return (
    <>
      <Row onClick={props?.onClick} style={liststyle}>
        {/* <div>
                    <div>
                        <img src={ListIcon}></img>
                    </div>
                </div> */}
        <div className={Styles.perinfo} span={10}>
          <div>{props?.item?.name}</div>
          <div>{ageAndGenderString}</div>
        </div>
      </Row>
    </>
  );
});

export default DrListItem;
