const config = {
    apiKey: "AIzaSyAhZKCDC8QclfH3PX5Q_hPD2c744663rw0",
    authDomain: "cohesive-idiom-297216.firebaseapp.com",
    projectId: "cohesive-idiom-297216",
    storageBucket: "cohesive-idiom-297216.appspot.com",
    messagingSenderId: "692022581179",
    appId: "1:692022581179:web:556c009d38646e97d8569e",
    measurementId: "G-HNL2X78N8Z"
};

export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
        throw new Error('No Firebase configuration object provided.' + '\n' +
            'Add your web app\'s configuration object to firebase-config.js');
    } else {
        return config;
    }
}