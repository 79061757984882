import { Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Colours } from "../../Colours";
import "./NavLink.css";

const DrNavLink = ({ to, title, Icon, ...otherProps }) => {
  // console.log({ to, title, iconName, iconType, otherProps });
  return (
    <NavLink
      to={to}
      className="row ml-0 mr-0 nav-route"
      activeClassName="nav-route-active"
      exact={true}
    >
      <div
        id="left-highlight"
        style={{
          height: "100%",
          width: "5px",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
      <Col xs={2}>
        <Icon
          id="icon"
          className="align-self-center"
          color={Colours.maroon}
          style={{ verticalAlign: "text-top" }}
        />
      </Col>
      <Col style={{ justifyContent: "left", color: Colours.primaryText }}>
        {title}
      </Col>
    </NavLink>
  );
};

export default DrNavLink;
