
import {

    makeAutoObservable,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
import { message } from "antd";
class Store {

    technicianDetail = {
        email: '',
        phone: '',
        address: ''
    }

    constructor() {
        makeAutoObservable(this, {

        });
    }

    limit = 10
    skip = 0
    getMoreCount = 0;
    pulledCount = 0;
    pullingCount = -1;
    searchString = '';
    setSearchString = (val) => {
        this.searchString = val;
        this.getMoreCount = 0;
        this.pullingCount = -1
        this.skip = 0;
        console.log(this.searchString);
        this.getTechnicianList(true);
    }
    getTechnicianList = async (replace = false) => {
        dbstore.checkSession()

        dbstore.setLoading(true)
        if (this.pullingCount >= this.getMoreCount) {
            return
        }
        this.pullingCount = this.getMoreCount;
        var path = '/admin/technicians';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.limit}&skip=${this.skip}&search=${this.searchString}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.technicianList.concat(res);
        this.technicianList = []
        Object.assign(this.technicianList, temp);
        dbstore.setLoading(false)
    }

    onScrollEndReached = () => {
        this.getMoreCount++;
        this.skip = this.getMoreCount * this.limit;
        this.getTechnicianList();
    }

    selectTechnician = (_id) => {
        console.log(_id);
        var tec = this.technicianList.find(item => item._id == _id);
        this.selectedTechnicianDetails.email = tec.email;
        this.selectedTechnicianDetails.phone = tec.phone;
        this.selectedTechnicianDetails.address = tec.address ? Object.values(tec.address).join(',') : '';
        this.selectedTechnicianDetails._id = tec._id;
        this.selectedTechnicianDetails.name = tec.name;
        this.selectedTechnicianDetails.age = tec.age;
        this.selectedTechnicianDetails.gender = tec.gender;
        this.selectedTechnicianDetails.homeClinic = tec.phName;
        this.selectedTechnicianDetails.phid = tec.phid;
        this.selectedTechnicianDetails.status = tec.status || 'active'

        this.getAlottedPatients(tec.phid, true);


    }

    ptLimit = 10;
    ptSkip = 0;
    ptPullingCount = -1;
    ptGetMoreCount = 0;
    getAlottedPatients = async (phid, replace = false) => {
        dbstore.checkSession()

        if (!phid) {
            phid = this.selectedTechnicianDetails.phid;
        }
        console.log(phid);
        dbstore.setLoading(true)
        if (this.ptPullingCount >= this.ptGetMoreCount) {
            return
        }
        this.ptGetMoreCount = this.ptGetMoreCount;
        var path = '/admin/patients';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.ptLimit}&skip=${this.ptSkip}&phid=${phid}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.patientList.concat(res);

        this.patientList = []
        Object.assign(this.patientList, temp);
        dbstore.setLoading(false)
    }

    setTechnicianToPharmacy = async (technicianId) => {
        dbstore.checkSession()

        var raw = {
            "condition": { "_id": dbstore.pharmacyId },
            "data": { "assignedTechnician": technicianId }
        }

        var path = '/admin/pharmacies';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;

        res = await res.json();

        console.log("status is", status);

        if (status == 200) {
            message.success('Pharmacy updated successfully')

        } else {
            message.error("Oops something went wrong")
        }
    }
    onPtScrollEndReached = () => {
        this.ptGetMoreCount++;
        this.ptSkip = this.ptGetMoreCount * this.ptLimit;
        this.getAlottedPatients();
    }
    patientList = []


    technicianList = [

    ]


    setTechnicianDetails(key, value) {
        this.patientDetails[key] = value;
    }

    getTechnicianDetails() {
        console.log('Get patient details called');
    }
    handleSearch(context) {
        console.log(context);
    }
    handleFilter(context) {
        console.log(context);
    }
    handleNav(nav) {
        console.log(nav);
    }
    handleAddTechnician() {
        console.log('Add Technician');
    }

    selectedTechnicianDetails = {

        name: "Technician 3",
        age: "22",
        gender: "M",
        email: "yashwandhare124@gmail.com",
        phone: "+1 234 567 98",
        address: "124 Villa Clinic Honover, MD 20176",
        homeClinic: "124 Villa Clinic Honover, MD 20176",
        AssignedDoctor: {
            image: "",
            name: "John Doe",
            email: "yashwandhare124@gmail.com",
            phone: "+1 234 567 98",
        },
        PatientsAlloted: [
            {
                name: "patient 1",
                age: "20",
                gender: "M"
            },
            {
                name: "patient 2",
                age: "21",
                gender: "F"
            },
            {
                name: "patient 3",
                age: "22",
                gender: "F"
            }
        ]
    }


    updateObj = {
        email: null,
        phone: null
    }
    setUpdateObj = (key, val) => {
        this.updateObj[key] = val;
    }
    updateTechnician = async () => {
        // if (!this.updateObj.email && !this.updateObj.phone) {
        //     message.error('No data changed')
        //     return
        // }
        dbstore.checkSession()

        var obj = {

        };
        var emailChanged = false;
        var phoneChanged = false;
        if (this.updateObj.email || this.updateObj.email == '') {
            if (this.updateObj.email != this.selectedTechnicianDetails.email) {
                obj.email = this.updateObj.email
                emailChanged = true
            }
        }
        if (this.updateObj.phone || this.updateObj.phone == '') {
            if (this.updateObj.phone != this.selectedTechnicianDetails.phone) {
                obj.phone = this.updateObj.phone
                phoneChanged = true
            }
        }
        obj.tecid = this.selectedTechnicianDetails._id;


        console.log(obj);
        // return
        var raw = obj
        var path = '/admin/technicians';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;

        res = await res.json();

        console.log(res, 'technician update')

        if (status == 200) {
            message.success('Technician updated successfully')
            if (emailChanged) {
                this.selectedTechnicianDetails.email = obj.email
            }
            if (phoneChanged) {
                this.selectedTechnicianDetails.phone = obj.phone
            }
        } else {
            message.error("Oops something went wrong")
        }


        this.updateObj.email = null
        this.updateObj.phone = null



        console.log(obj)
    }


    updateActiveStatus = async () => {
        var status;
        dbstore.checkSession()

        if ('status' in this.selectedTechnicianDetails) {
            status = this.selectedTechnicianDetails.status == 'active' ? 'inactive' : 'active'
        } else {
            status = 'inactive'
        }

        var obj = {}
        obj.status = status;
        obj.tecid = this.selectedTechnicianDetails._id;

        var raw = obj
        var path = '/admin/technicians';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var s = res.status;

        res = await res.json();

        console.log(res, 'technician update')

        if (s == 200) {
            message.success('Technician activation changed')
            this.selectedTechnicianDetails.status = status
        } else {
            message.error("Oops something went wrong")
        }



    }







}

const technicianStore = new Store();
export default technicianStore;






