import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";


const ProfilePic = observer(
  ({ store, scale, entity = "user", style, avatar }) => {
    var defaultPic = "https://www.gravatar.com/avatar/?d=mp" //"https://i.pravatar.cc/200";
    // console.log(avatar);
    // if (avatar) {
    //   defaultPic = avatar;
    // }
    const styles = {
      picture: { height: 100 * scale, width: 100 * scale },
    };
    const [profilePic, setProfilePic] = useState(avatar);
    const count = useRef(0);

    return (

      <Image
        style={{ ...styles.picture, ...style }}
        src={avatar ? avatar : defaultPic}

        onError={() => {
          console.log("image errror");
          count.current++;
          if (count.current >= 2) {
            setProfilePic(defaultPic);
          } else {
            //store.getViewUrl("profilepic");
          }
        }}
      />

    );
  }
);
export default ProfilePic;
