
import {Col,Row,Layout} from 'antd'
import {useEffect,useState} from 'react'
var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
};
const PatientReports = ({ props }) => {
    return (
      <>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <div style={box}></div>
          </Col>
          <Col span={24}>
            <div style={box}></div>
          </Col>
          <Col span={24}>
            <div style={box}></div>
          </Col>
        </Row>
      </>
    );
  };
export default PatientReports;