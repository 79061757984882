/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const PhIcon = () => (
  <svg width={26.997} height={23.997} viewBox="0 0 26.997 23.997">

    <path id="Icon_material-store-mall-directory" data-name="Icon material-store-mall-directory" d="M30,6H6V9H30Zm1.5,15V18L30,10.5H6L4.5,18v3H6v9H21V21h6v9h3V21ZM18,27H9V21h9Z" transform="translate(-4.5 -6)" fill="#a01027" />
  </svg>
);

export default PhIcon;
