import React from 'react';
import { Col, Row, Layout } from 'antd';
import ListIcon from '../../assets/animations/drp1/Calendar-Day-M.png';
import { observer } from 'mobx-react';
import _ from 'lodash';

const liststyle = {
    background: "#f0f0f7",
    padding: "1em",
    borderRadius: "1em",
    marginBottom: "1em"
}
const box = {
    paddingTop: "auto",
    paddingBottom: "auto"
}

const ListEle = observer((props) => {
    return (
        <>
            <Row style={liststyle}>
                <Col span={6} style={box}>
                    <div>
                        {props?.item?.time}
                    </div>
                    <div>
                        {props?.item?.date}
                    </div>
                </Col>
                <Col span={4}>
                    {!props?.patient ? <div>
                        <img src={ListIcon}></img>
                    </div> :
                        <div>
                            <div style={{ display: "flex", alignItems: "center", textAlign: "center", background: "#19d0c3", color: "#fff", width: "5em", height: "5em", justifyContent: "center", borderRadius: "50%" }}>
                                {
                                    props?.item?.image ? <img src={props?.item?.image}></img>
                                        :
                                        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2em" }}>{props?.item?.name?.[0]}</div>
                                }
                            </div>
                        </div>}
                </Col>
                <Col span={10}>
                    {!props?.patient ?
                        <><div>{_.capitalize(props?.item?.prescriptionName)}</div>
                            <div style={{ marginTop: 10 }}>{props?.item?.direction}</div></> :
                        <><div>{props?.item?.name}</div>
                            <div>{props?.item?.age} | {props?.item?.gender}</div></>}
                </Col>
                <Col span={4} align="center">
                    {/* <div>
                        <a>View More</a>
                    </div> */}
                </Col>
            </Row>
        </>
    );
})

export default ListEle;