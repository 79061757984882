import { Col, Row, Layout, Input, Empty } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Image, DropdownButton, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import Technician from '../../assets/images/pharmacist.png';
import Clinic from '../../assets/images/XMLID_412_.png';
import Patient from '../../assets/images/patientIco.png';
import Doctor from '../../assets/images/Path 37556.png';
import ListEle from '../common/ListEle';
import Styles from '../Styles/HomeStyle.module.css';
import DrListItem from '../common/DrListItem';
import Msg from '../../assets/animations/drp1/Icon material-email.png';
import Phone from '../../assets/animations/drp1/Icon awesome-phone-alt.png';
import Person from '../../assets/animations/drp1/Icon awesome-map-marked-alt.png';
import Video from '../../assets/animations/drp1/Group 70.png';
import Audio from '../../assets/animations/drp1/Group 71.png';
import Message from '../../assets/animations/drp1/Icon ionic-ios-chatbubbles.png';
import Home from '../../assets/animations/drp1/Icon material-store-mall-directory.png';
import doctorStore from '../../dataLayer/doctors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropInput from '../common/DropInput';
import HelpStore from '../../dataLayer/help';
import { observer } from 'mobx-react';
import _ from 'lodash';





var box = {
  // minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  // width:"fit-content",
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};
const HelpCenter = observer(({ props }) => {

  useEffect(() => {
    if (!HelpStore.retrieved) {
      HelpStore.getConversations()
    }

    var func = () => {
      messageEnd.current.scrollIntoView({ behavior: "smooth" })
    }
    HelpStore.setSTB(
      func

    )



  }, [])


  const messageEnd = useRef(null);
  const inputMessage = useRef(null);
  const docDetails = doctorStore?.selectedDoctorDetails;
  const [sort, setSort] = useState('None');
  const [nav, setNav] = useState([true, false, false]);
  const handleClickNav = (index) => {
    var temp = [false, false, false];
    temp[index] = true;
    setNav(temp);
  }
  // const classcont = useRef(0);
  // const scrollToMyRef = () => {
  //   const scroll =
  //     classcont.current.scrollHeight -
  //     classcont.current.clientHeight;
  //     console.log(scroll)
  //   classcont.current.scrollTo(0, scroll);
  // };
  // useEffect(()=>{
  //   scrollToMyRef();
  // })
  return (
    <div style={Styles?.main}>
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Help Center</h2>
            </div>

          </div>
        </Col>

        <Col span={8}>
          {/* <div className={Styles.topnav}>
            <h6 className={nav[0] && Styles.selectednav} onClick={() => handleClickNav(0)}>Doctors</h6>
            <h6 className={nav[1] && Styles.selectednav} onClick={() => handleClickNav(1)}>Technicians</h6>
            <h6 className={nav[2] && Styles.selectednav} onClick={() => handleClickNav(2)}>Patients</h6>
          </div> */}
          <Row>
            <Col span={24}>
              <div style={{ ...box, height: '70vh', overflow: 'scroll' }}>
                <div>
                  {/* <DropInput /> */}
                </div>
                {
                  HelpStore.users.map((item, index) => {
                    return (
                      <DrListItem item={item} onClick={() => { HelpStore.handleUserClick(item) }} />
                    );
                  })
                }
              </div>
            </Col>
          </Row>

        </Col>
        <Col span={16}>
          <div className={Styles.rightbox + ' ' + Styles.chatside} style={box}>
            {HelpStore.currentUser ? <> <div className={Styles.chatheader}>
              <div>
                <div>
                  <img></img>
                </div>
                <div>
                  <h5>{HelpStore.currentUserName}</h5>
                  <div>{_.capitalize(HelpStore.currentUserType)}</div>
                </div>
              </div>
            </div>
              <div className={Styles.messagebox}>
                {
                  HelpStore.messages?.map((item, index) => (
                    <div>
                      {item?.user === 0 ? <div>
                        <div className={Styles.reciever}>
                          {item?.content}
                        </div>
                        <div>
                          {item?.time}
                        </div>
                      </div> :
                        <div style={{ float: "right" }}>
                          <div className={Styles.sender}>
                            {item?.content}
                          </div>
                          <div>
                            {item?.time}
                          </div>
                        </div>}
                    </div>
                  ))
                }
                <div style={{ backgroundColor: 'red', height: 0, marginTop: 100, width: 0 }}
                  ref={messageEnd}>

                </div>
              </div>
              <div className={Styles.chatinput}>
                <input
                  ref={inputMessage}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      HelpStore.sendNewMessage(e.target.value);
                      messageEnd.current.scrollIntoView({ behavior: "smooth" });
                      inputMessage.current.value = '';

                    }
                  }} placeholder='Type your message here...'></input>
                <div>
                  <FontAwesomeIcon icon={"paperclip"} size={"lg"} />
                </div>
              </div></> : <Empty />}
          </div>
        </Col>
      </Row>
    </div>
  );
});
export default HelpCenter;