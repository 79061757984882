
import {

    makeAutoObservable,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
import _ from "lodash";
class Store {

    constructor() {
        makeAutoObservable(this, {

        });
    }

    limit = 10
    skip = 0
    getMoreCount = 0;
    pulledCount = 0;
    pullingCount = -1;
    searchString = '';
    setSearchString = (val) => {
        this.searchString = val;
        this.getMoreCount = 0;
        this.pullingCount = -1
        this.skip = 0;
        console.log(this.searchString);
        this.getTechnicianList(true);
    }
    getTechnicianList = async (replace = false) => {
        dbstore.checkSession()

        dbstore.setLoading(true)
        if (this.pullingCount >= this.getMoreCount) {
            return
        }
        this.pullingCount = this.getMoreCount;
        var path = '/admin/technicians';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.limit}&skip=${this.skip}&search=${this.searchString}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.technicianList.concat(res);
        this.technicianList = []
        Object.assign(this.technicianList, temp);
        dbstore.setLoading(false)
    }

    onScrollEndReached = () => {
        this.getMoreCount++;
        this.skip = this.getMoreCount * this.limit;
        this.getTechnicianList();
    }
    docLimit = 10
    docSkip = 0
    docGetMoreCount = 0;
    docPulledCount = 0;
    docPullingCount = -1;
    docSearchString = '';
    setDocSearchString = (val) => {
        this.docSearchString = val;
        this.docGetMoreCount = 0;
        this.docPullingCount = -1
        this.docSkip = 0;
        console.log(this.docSearchString);
        this.getDoctorList(true);
    }
    getDoctorList = async (replace = false) => {
        dbstore.checkSession()

        dbstore.setLoading(true)
        if (this.docPullingCount >= this.docGetMoreCount) {
            return
        }
        this.docPullingCount = this.docGetMoreCount;
        var path = '/admin/doctors';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.docLimit}&skip=${this.docSkip}&search=${this.docSearchString}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.doctorList.concat(res);
        this.doctorList = []
        Object.assign(this.doctorList, temp);
        dbstore.setLoading(false)
    }

    onDoctorScrollEndReached = () => {
        this.docGetMoreCount++;
        this.docSkip = this.docGetMoreCount * this.docLimit;
        this.getDoctorList();
    }

    getReports = async (type, id) => {
        dbstore.checkSession()

        var docid = '';
        var phid = '';
        if (type == 'doctor') {
            docid = id;
        }
        else {
            phid = id;
        }
        var path = '/admin/reports';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?docid=${docid}&phid=${phid}`,
            options,

        );
        res = await res.json();
        console.log(res);

        if (res.stats) {
            this.reportDetail.PatientsVisited = res.stats.patCount
            this.reportDetail.PrescriptionsFiled = res.stats.rxCount
        } else {
            this.reportDetail.PatientsVisited = 0
            this.reportDetail.PrescriptionsFiled = 0
        }

        var pcData = [];

        //sort by _id
        pcData = _.sortBy(res.pcData, '_id');

        this.reportData.graphData = pcData;
        this.reportData.timePeriod = pcData.length

    }

    doctorList = [

    ]
    technicianList = [

    ]
    reportDetail = {
        PatientsVisited: 0,
        PrescriptionsFiled: 0,
        RefillsCompleted: 0,
        PAsCompleted: 0
    }

    reportData = {
        graphData: [],
        timePeriod: 0
    }

    getReportDetails() {
        console.log('Get Report Details called');
    }
    handleDownloadReport() {
        console.log('Download report function called');
    }
    getDoctorList() {
        console.log('get doctor list fired');
    }
    getTechnicianList() {
        console.log('get technician list called');
    }
    handleSearch() {
        console.log('handle search called');
    }
    handleFilter() {
        console.log('handle filter called');
    }
    handleDayFilter() {
        console.log('handle date filter called');
    }
    handleNav(nav) {
        console.log(nav);
    }
}
const reportStore = new Store();
export default reportStore;