import React from 'react';
import {Col,Row,Layout} from 'antd';
import ListIcon from '../../assets/animations/drp1/Calendar-Day-M.png';
import Styles from '../Styles/HomeStyle.module.css';

const liststyle = {
    background:"#f0f0f7",
    padding:"1em",
    borderRadius:"1em",
    marginBottom:"1em",
    display:"flex",
    flexDirection:"row",
    alignItems:"center"
}
const box = {
    paddingTop:"auto",
    paddingBottom:"auto"
}

function PresListItem(props){
    return (
        <>
            <Row style={liststyle}>
                <div>
                    <div>
                        <img src={ListIcon}></img>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between",flex:1}}>
                    <div className={Styles.perinfo} span={10}>
                        <div>{props?.item?.title}</div>
                        <div>{props?.item?.name}</div>
                    </div>
                    <div>
                        <div>{props?.item?.time}</div>
                        <div>{props?.item?.date}</div>
                    </div>
                </div>
            </Row>
        </>
    );
}

export default PresListItem;