import { Link } from "react-router-dom";
import { Col, Container, Image, InputGroup, Row } from "react-bootstrap";
import logo from "../../assets/images/logo@2x.png";
import logoName from "../../assets/images/logoname@2x.png";
import DrNavLink from "./NavLink";
import patientIcon from "../../assets/images/patient.png";
import { HiOutlineHome, HiSupport, HiMail } from "react-icons/hi";
import { BiTask } from "react-icons/bi";
import {
  RiBarChart2Fill,
  RiMailLine,
  RiBarcodeLine,
  RiUserFill,
  RiSettings5Fill,
} from "react-icons/ri";
import { IoPersonOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
var main = {
  display: "flex",
  flexDirection: "row",
  alignContent: "start"
}
var searchside = {
  background: "#fff"
}
const SideNav = (props) => {
  return (
    <div style={main}>
      <div
        className="p-0 bg-white full-height pt-3"
        style={{ overflow: "hidden" }}
      >
        <Link to="/" className="navbar-brand  mr-0 mb-2">
          <Container>
            <Row className="ml-0 mr-0">
              <Col xs={12}>
                <Image src={logo} fluid />
              </Col>
              <Col xs={12}>
                <Image src={logoName} fluid />
              </Col>
            </Row>
          </Container>
        </Link>
        <DrNavLink to="/home" title={"Home"} Icon={HiOutlineHome}></DrNavLink>
        {/* <DrNavLink to="/requests" title={"Requests"} Icon={HiMail}></DrNavLink> */}
        <DrNavLink to="/doctors" title={"Doctors"} Icon={BiTask}></DrNavLink>
        <DrNavLink
          to="/technicians"
          title={"Technicians"}
          Icon={RiUserFill}
        ></DrNavLink>
        <DrNavLink
          to="/patients"
          title={"Patients"}
          Icon={() => <Image src={patientIcon} />}
        ></DrNavLink>
        <DrNavLink
          to="/reports"
          title={"Reports"}
          Icon={FaRegCalendarAlt}
        ></DrNavLink>

        <DrNavLink to="/help" title={"Help Center"} Icon={HiSupport}></DrNavLink>
        <DrNavLink
          to="/settings"
          title={"User Settings"}
          Icon={RiSettings5Fill}
        ></DrNavLink>

        <DrNavLink
          to="/phsettings"
          title={"Pharmacy Settings"}
          Icon={RiSettings5Fill}
        ></DrNavLink>


        {/* <DrNavLink
        to="/settings"
        title={"Settings"}
        Icon={IoMdSettings}
      ></DrNavLink> */}
      </div>
    </div>
  );
};

export default SideNav;
