import Cookies from 'js-cookie'

import {

    makeAutoObservable,

} from "mobx";
import jwt_decode from "jwt-decode";

class Store {
    constructor() {
        makeAutoObservable(this, {

        });
    }

    //endpoint = "https://api.doctorspharmacy.us"
    endpoint = process.env.REACT_APP_SERVER_URL
    loading = false
    login = false
    pharmacyId = "5958c09b-a412-4b2a-9c17-02c5487561a7";

    setLoading = (val) => {
        this.loading = val;
    }

    loginFunc = async (email, password) => {
        console.log('login ', email, password)

        var raw = {
            email: email,
            password: password
        }
        var res = await fetch(
            `${this.endpoint}/auth/admin/login`,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',
                body: JSON.stringify(raw)
            }
        );

        var status = res.status;
        res = await res.json();
        console.log(res, status);
        if (status == 200) {
            console.log("login suc")
            this.login = true;



        }
        return status;
    }

    *logoutFunc() {
        this.loading = true;
        var res = yield fetch(
            `${this.endpoint}/auth/admin/logout`,
            {
                method: "post",
                headers: { "Content-Type": "application/json" },
                credentials: 'include',

            }
        );

        var status = res.status;
        res = yield res.json();
        // console.log(res, status);
        if (status == 200) {
            this.loading = false
            this.login = false;
            // this.resetStore()
        }
        return status;
    }

    checkSession() {
        this.login = Cookies.get('alogin') == 'true'

    }



    refreshStore() {


        this.login = Cookies.get('alogin') == 'true'
        if (this.login)
            this.decodedUserId = Cookies.get('atoken') ? jwt_decode(Cookies.get('atoken'))._id : null
        // console.log(this.decodedUserId, Cookies.get('token'), 'decoded')
        // console.log(this.login)
    }

}

const dbstore = new Store();
export default dbstore