
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Layout } from 'antd'
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react'
import PhSettingsStore from '../../dataLayer/phSettings';
import Styles from '../Styles/HomeStyle.module.css';

var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  padding: 40
};
const PhSettings = observer(({ props }) => {
  useEffect(() => {
    PhSettingsStore.getPhDetails()
  }, [])
  return (
    <>
      <Row style={{ margin: 40 }} gutter={[32, 32]}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Pharmacy Settings</h2>
            </div>

          </div>
        </Col>
        <Col span={24}>
          <div style={box}>
            <div className={Styles.inputfield}>
              <span style={{ fontSize: 24, fontWeight: 'bold', marginRight: 20, minWidth: 250 }}>PioneerRx Port:</span>
              <div>
                <FontAwesomeIcon icon={'search'} size={'lg'} />
                <input
                  disabled={!PhSettingsStore.editable}
                  value={PhSettingsStore.phDetails.prxPort}
                  onChange={(e) => {
                    // onChange(e.target.value)
                    PhSettingsStore.setPrxPort(e.target.value)
                  }} placeholder={'Pioneer Rx Port'}></input>
              </div>
            </div>
            <div className={Styles.inputfield}>
              <span style={{ fontSize: 24, fontWeight: 'bold', marginRight: 20, minWidth: 250 }}>RxSafe Port:</span>
              <div>
                <FontAwesomeIcon icon={'search'} size={'lg'} />
                <input
                  disabled={!PhSettingsStore.editable}
                  value={PhSettingsStore.phDetails.rxSafePort}
                  onChange={(e) => {
                    PhSettingsStore.setRxSafePort(e.target.value)

                    // onChange(e.target.value)
                  }} placeholder={' Rx Safe Port'}></input>
              </div>
            </div>
            <div className={Styles.inputfield}>
              <span style={{ fontSize: 24, fontWeight: 'bold', marginRight: 20, minWidth: 250 }}>PioneerRx Service:</span>
              <div style={{ backgroundColor: PhSettingsStore.phDetails.prxStatus == 'active' ? '#080' : '#f00', padding: 10, borderRadius: 20 }}>
                <span style={{ fontSize: 18, fontWeight: 'bold', marginRight: 20, minWidth: 250, textAlign: 'center', margin: 'auto', color: "#fff" }}>{_.capitalize(PhSettingsStore.phDetails.prxStatus)}</span>

              </div>
            </div>
            <div className={Styles.inputfield}>
              <span style={{ fontSize: 24, fontWeight: 'bold', marginRight: 20, minWidth: 250 }}>RxSafe Service:</span>
              <div style={{ backgroundColor: PhSettingsStore.phDetails.rxSafeStatus == 'active' ? '#080' : '#f00', padding: 10, borderRadius: 20 }}>
                <span style={{ fontSize: 18, fontWeight: 'bold', marginRight: 20, minWidth: 250, textAlign: 'center', margin: 'auto', color: "#fff" }}>{_.capitalize(PhSettingsStore.phDetails.rxSafeStatus)}</span>

              </div>
            </div>

            <div
              onClick={() => { PhSettingsStore.submit() }}
              style={{
                backgroundColor: PhSettingsStore.editable ? '#080' : '#f00', marginTop: 30,
                padding: 10, borderRadius: 20, width: 150, justifyContent: 'center',
                alignItems: 'center', display: 'flex', cursor: 'pointer'
              }}>
              <span style={{ fontSize: 18, fontWeight: 'bold', marginRight: 20, textAlign: 'center', color: "#fff" }}>{PhSettingsStore.editable ? 'Save' : 'Edit'}</span>

            </div>

          </div>
        </Col>

      </Row>
    </>
  );
});
export default PhSettings;