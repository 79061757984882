
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Home from "../routes/home.js"

import Requests from "../routes/requests.js"

import Doctors from "../routes/doctors.js"

import Technicians from "../routes/technicians.js"

import Patients from "../routes/patients.js"

import PatientVitals from "../routes/patientVitals.js"

import PatientReports from "../routes/patientReports.js"

import PatientVitalDetails from "../routes/patientVitalDetails.js"

import PatientCalendar from "../routes/patientCalendar.js"

import Reports from "../routes/reports.js"

import Help from "../routes/help.js"

import Settings from "../routes/settings.js"
import PhSettings from "../routes/phsettings.js";
import ReportsWrapper from "../routes/reports/ReportsWrapper.js";

const RouteSwitcher = (props) => {
    let { path, url } = useRouteMatch();

    return (
        <Switch>

            <Route path="/home" exact>
                <Home />
            </Route>

            <Route path="/requests" exact>
                <Requests />
            </Route>

            <Route path="/doctors" exact>
                <Doctors />
            </Route>

            <Route path="/technicians" exact>
                <Technicians />
            </Route>

            <Route path="/patients" exact>
                <Patients />
            </Route>

            <Route path="/patientVitals" exact>
                <PatientVitals />
            </Route>

            <Route path="/patientReports" exact>
                <PatientReports />
            </Route>

            <Route path="/patientVitalDetails" exact>
                <PatientVitalDetails />
            </Route>

            <Route path="/patientCalendar" exact>
                <PatientCalendar />
            </Route>

            <Route path="/reports" exact>
                <ReportsWrapper />
            </Route>

            <Route path="/help" exact>
                <Help />
            </Route>

            <Route path="/settings" exact>
                <Settings />
            </Route>
            <Route path="/phsettings" exact>
                <PhSettings />
            </Route>
            <Route exact path="/">
                <Redirect to={"/home"} />
            </Route>
            <Route path="/">
                <>Page Not Found</>
            </Route>
        </Switch>
    );
};

export default RouteSwitcher;
