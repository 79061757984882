import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import patientStore from '../../dataLayer/patients';
import Styles from './ModalStyle.module.css';
const PrescriptionModal = observer((props) => {
    const handleClose = () => {
        props?.handleClose();
    }
    const [data, setData] = useState()

    return (
        <>
            <Modal
                show={props?.show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title style={{ width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: ".5em 1em" }}>
                            <h2 style={{ flex: 1, fontWeight: "bold" }}>{props?.title}</h2>
                            <div>
                                <FontAwesomeIcon icon={"times"} onClick={handleClose}></FontAwesomeIcon>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={Styles.tablediv}>
                        <table>
                            <tr className={Styles.tablehead}>
                                <th>
                                    Rx Number
                                </th>
                                <th>
                                    Prescriber Name
                                </th>
                                <th>
                                    Specialization
                                </th>
                                <th>
                                    Address
                                </th>
                                <th>
                                    Last Update
                                </th>
                                <th>
                                    Rx Name
                                </th>
                                {/* <th>
                                    Directions
                                </th> */}
                            </tr>
                            {
                                patientStore.patientRx?.map((item, index) => (
                                    <tr>
                                        <td>
                                            {item?.rxno}
                                        </td>
                                        <td>
                                            {item?.docName}
                                        </td>
                                        <td>
                                            {item?.docSpecialization}
                                        </td>
                                        <td>
                                            {item?.address}
                                        </td>
                                        <td>
                                            {item?.lastUpdate}
                                        </td>
                                        <td>
                                            {item?.name}
                                        </td>
                                        {/* <td>
                                            {item?.directions}
                                        </td> */}
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ textAlign: "center", width: "100%" }}>
                    {/* <a style={{ color: "#3B86FF", width: "100%" }}>Show more</a> */}
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default PrescriptionModal;