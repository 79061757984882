
import { Col, Row, Layout, Input } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { SearchOutlined } from "@ant-design/icons";
import patientStore from '../../dataLayer/patients';
import { observer } from 'mobx-react';
import homeStore from '../../dataLayer/home';
import AddModal from '../modals/AddModal';

var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
};
const Settings = observer(({ props }) => {
  useEffect(() => {
    patientStore.getPatientList();
    patientStore.setSearchString('')

  }, [])
  const [show, setShow] = useState(false);

  const handleModal = () => {
    setShow(!show);
  }

  const listInnerRef = useRef();
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom');

        patientStore.onScrollEndReached()
      }
    }
  };
  return (
    <>
      <AddModal
        show={show}
        title={"Add Patient"}
        handleAdd={async () => {
          await homeStore.handleAddPatient(); setShow(false)
        }}
        handleClose={handleModal}
      />
      <Row gutter={[32, 32]} style={{ padding: 40 }}>
        <Col span={24}>
          <div style={{}}>
            <h3>User Settings</h3>
          </div>
        </Col>
        <Col span={24}>
          <Row >
            <Col span={20} >
              <div style={{ width: 300 }}>
                <Input
                  size="large"
                  onChange={(e) => {
                    console.log(e.target.value)
                    patientStore.setSearchString(e.target.value)
                  }}
                  style={{ borderRadius: 10, height: 60 }}
                  width={200}
                  bordered={true}
                  placeholder="Enter Last Name"
                  prefix={<SearchOutlined style={{ color: "#333" }} />}
                />
              </div>
            </Col>
            <Col style={{ float: 'right' }}>
              <div style={{
                backgroundColor: "#23D098", width: 100, height: 40,
                float: 'right', display: 'flex', justifyContent: 'center', cursor: 'pointer',
                alignItems: 'center', borderRadius: 10
              }}
                onClick={() => {
                  handleModal()
                }}
              >
                <p style={{ textAlign: 'center', color: "#fff", marginTop: 10 }}>ADD USER</p>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div onScroll={() => onScroll()} ref={listInnerRef} style={{ height: '60vh', padding: 20, backgroundColor: "#fff", borderRadius: 20, overflow: 'scroll' }}>
            <Row >
              <Col span={5}>
                <p style={{ fontSize: 18, color: "#43425D", fontWeight: 'bold' }}>User Id</p>
              </Col>
              <Col span={5}>
                <p style={{ fontSize: 18, color: "#43425D", fontWeight: 'bold' }}>Full Name</p>
              </Col>
              <Col span={5}>
                <p style={{ fontSize: 18, color: "#43425D", fontWeight: 'bold' }}>Contact No</p>
              </Col>
              <Col span={5}>
                <p style={{ fontSize: 18, color: "#43425D", fontWeight: 'bold' }}>Email Id</p>
              </Col>
              <Col span={4}>
                <p style={{ fontSize: 18, color: "#43425D", fontWeight: 'bold' }}>Action</p>
              </Col>
              <Col span={24} style={{ height: 2, backgroundColor: "#F1F1F3" }}></Col>
            </Row>
            {patientStore.patientList.map((item, index) => {
              return <Row style={{ backgroundColor: index % 2 == 0 ? "#fff" : "#EFEFF6", paddingTop: 10 }}>
                <Col span={5}>
                  <p style={{ fontSize: 16, color: "#43425D", }}>{item._id.substring(15)}</p>
                </Col>
                <Col span={5}>
                  <p style={{ fontSize: 16, color: "#43425D", }}>{item.name}</p>
                </Col>
                <Col span={5}>
                  <p style={{ fontSize: 16, color: "#43425D", }}>{item.phone}</p>
                </Col>
                <Col span={5}>
                  <p style={{ fontSize: 16, color: "#43425D", }}>{item.email}</p>
                </Col>
                <Col span={4}>
                  <p onClick={() => {
                    patientStore.selectPatient(item._id)
                    patientStore.updateActiveStatus()
                  }}
                    style={{ fontSize: 16, color: "#E80D0D", cursor: 'pointer' }}> {item.status == 'active' ? "Delete" : "Activate"}</p>
                </Col>
              </Row>
            })}
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Settings;