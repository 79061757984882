export const Colours = {
  primaryText: "#272D5C",
  activeNavLink: "#A3A0FB",
  secondaryText: "#4D4F5C",
  maroon: "#A01027",
  green: "#23D098",
  orange: "#FD532F",
  yellow: "#FFCA83",
  lightYellow: "#FFF4E5",
};
