
import {Col,Row,Layout} from 'antd'
import {useEffect,useState} from 'react'
import DrListItem from '../common/DrListItem';
import DropInput from '../common/DropInput';
import RequestStore from '../../dataLayer/requests';
import PresListItem from '../common/PresListItem';
import Styles from '../Styles/HomeStyle.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  padding:20,
  borderRadius:"2em",
  boxShadow:"0px 0px 5px 5px #e1e1e1"
};
var boxsec = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  padding:"4em 3em",
  borderRadius:"2em",
  boxShadow:"0px 0px 5px 5px #e1e1e1",

}
const Requests = ({ props }) => {
    const [med,setMed] = useState(true);
    const [pat,setPat] = useState(true);
    const docDetails = RequestStore?.selectedRequest;
    return (
      <>
        <Row gutter={[32, 32]} style={{padding:"0em 5em"}}>
          <Col span={24}>
            <div className={Styles.top}>
              <div>
                <h2>Requests</h2>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div style={box}>
              <div>
                <DropInput/>
              </div>
              <div style={{maxHeight:"50vh",overflowY:"scroll"}}>
                {
                  RequestStore?.requestsList?.map((item,index)=>{
                    return (
                      <PresListItem item={item}/>
                    );
                  })
                }
                </div>
            </div>
          </Col>
          <Col span={16}>
            <div className={Styles.rightbox} style={boxsec}>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <div style={{display:"flex",flexDirection:"row",}}>
                  <div style={{background:"rgba(35,208,152,1)",color:"#fff",borderRadius:"50%",width:"3em",height:"3em",fontSize:"1.5em",fontWeight:"bold",textAlign:"center",display:"flex",justifyContent:"center",alignItems:"center",margin:"0em 1em"}}><div>{docDetails?.name?.[0]}</div></div>
                  <div>
                    <h4>{docDetails?.drugName} by {docDetails?.doctorName}</h4>
                    <div style={{color:"#939393"}}>This Drug is Live for {docDetails?.liveFrom}</div>
                  </div>
                </div>
                <h4>To</h4>
                <h4>
                  {docDetails?.drugName}
                </h4>
              </div>
              <div>
                <div className={Styles.detailbox} onClick={()=>setMed(!med)}>
                  <div>Medication Details</div>
                  <div>
                    <FontAwesomeIcon icon={med?"chevron-up":"chevron-down"} />
                  </div>
                </div>
                {med&&<div className={Styles.mediDetails}>
                  <div>
                    Start date
                    <h6>{docDetails?.MedicationDetails?.startDate}</h6>
                  </div>
                  <div>
                    End date
                    <h6>{docDetails?.MedicationDetails?.endDate}</h6>
                  </div>
                  <div>
                    Refills Remaining
                    <h6>{docDetails?.MedicationDetails?.refillsRemaining}</h6>
                  </div>
                  <div>
                    Current Status
                    <h6>{docDetails?.MedicationDetails?.currentStatus}</h6>
                  </div>
                  <div>
                    Last Quantity
                    <h6>{docDetails?.MedicationDetails?.lastQuantity}</h6>
                  </div>
                  <div>
                    Last Fill
                    <h6>{docDetails?.MedicationDetails?.lastFill}</h6>
                  </div>
                </div>}
              </div>
              <div>
                <div className={Styles.detailbox} onClick={()=>setPat(!pat)}>
                  <div>Patient Details</div>
                  <div>
                    <FontAwesomeIcon icon={pat?"chevron-up":"chevron-down"} />
                  </div>
                </div>
                {pat&&<div className={Styles.mediDetails}>
                  <div>Patient Name: {docDetails?.PatientDetails?.name}</div>
                  <div>Age: {docDetails?.PatientDetails?.age}</div>
                  <div>Sex: {docDetails?.PatientDetails?.sex}</div>
                </div>}
              </div>
              <div className={Styles.actionsec}>
                  <a>Deny</a>
                  <a>Give Access</a>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
export default Requests;