class Store{
    requestsList=[
        {
            title:"Medicine Edit Access",
            name:"John Carn",
            time:"08:34AM",
            date:"02 AUG 2019"
        },
        {
            title:"Medicine Edit Access",
            name:"John Carn",
            time:"08:34AM",
            date:"02 AUG 2019"
        },
        {
            title:"Medicine Edit Access",
            name:"John Carn",
            time:"08:34AM",
            date:"02 AUG 2019"
        },
        {
            title:"Medicine Edit Access",
            name:"John Carn",
            time:"08:34AM",
            date:"02 AUG 2019"
        },
        {
            title:"Medicine Edit Access",
            name:"John Carn",
            time:"08:34AM",
            date:"02 AUG 2019"
        },
    ]
    selectedRequest={
        drugName:"Drug Name",
        doctorName:"Doctor Name",
        liveFrom:"6 months",
        MedicationDetails:{
            startDate:"01/01/2022",
            endDate:"",
            refillsRemaining:"",
            currentStatus:"",
            lastQuantity:"",
            lastFill:"",
        },
        PatientDetails:{
            name:"Mary Doe",
            age:58,
            sex:"Female"
        }
    }
}

const RequestStore = new Store();
export default RequestStore;