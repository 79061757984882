
import {

    makeAutoObservable,

} from "mobx";
import dbstore from "./dbstore";
import moment from "moment";
import { message } from "antd";
class Store {
    constructor() {
        makeAutoObservable(this, {

        });
    }

    limit = 10
    skip = 0
    getMoreCount = 0;
    pulledCount = 0;
    pullingCount = -1;
    searchString = '';
    setSearchString = (val) => {
        this.searchString = val;
        this.getMoreCount = 0;
        this.pullingCount = -1
        this.skip = 0;
        console.log(this.searchString);
        this.getDoctorList(true);
    }
    getDoctorList = async (replace = false) => {
        dbstore.checkSession()
        dbstore.setLoading(true)
        if (this.pullingCount >= this.getMoreCount) {
            return
        }
        this.pullingCount = this.getMoreCount;
        var path = '/admin/doctors';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.limit}&skip=${this.skip}&search=${this.searchString}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.doctorList.concat(res);
        this.doctorList = []
        Object.assign(this.doctorList, temp);
        dbstore.setLoading(false)
    }

    onDoctorScrollEndReached = () => {
        this.getMoreCount++;
        this.skip = this.getMoreCount * this.limit;
        this.getDoctorList();
    }

    selectDoctor = (_id) => {
        console.log(_id);
        var doc = this.doctorList.find(doc => doc._id === _id);

        this.selectedDoctorDetails.name = doc.name;
        this.selectedDoctorDetails.email = doc.email;
        this.selectedDoctorDetails.phone = doc.phone;
        this.selectedDoctorDetails.address = Object.values(doc.address).join(',') || '';

        this.selectedDoctorDetails._id = doc._id;
        this.selectedDoctorDetails.homeClinic = doc.phName
        this.selectedDoctorDetails.status = doc.status || 'active'

        this.getAlottedPatients(doc._id, true);

    }


    ptLimit = 10;
    ptSkip = 0;
    ptPullingCount = -1;
    ptGetMoreCount = 0;
    getAlottedPatients = async (docid, replace = false) => {
        dbstore.checkSession()
        if (!docid) {
            docid = this.selectedDoctorDetails._id;
        }
        console.log(docid);
        dbstore.setLoading(true)
        if (this.ptPullingCount >= this.ptGetMoreCount) {
            return
        }
        this.ptGetMoreCount = this.ptGetMoreCount;
        var path = '/admin/patients';
        var options = {
            method: "get",

            credentials: 'include'
            // headers: { "Content-Type": "application/json" },
        }
        options['cache'] = "no-cache"

        var res = await fetch(
            `${dbstore.endpoint}${path}?limit=${this.ptLimit}&skip=${this.ptSkip}&docid=${docid}`,
            options,

        );
        res = await res.json();
        console.log(res);
        var temp = replace ? res : this.patientList.concat(res);

        this.patientList = []
        Object.assign(this.patientList, temp);
        dbstore.setLoading(false)
    }

    setDoctorToPharmacy = async (doctorId) => {
        dbstore.checkSession()

        var raw = {
            "condition": { "_id": dbstore.pharmacyId },
            "data": { "assignedDoctor": doctorId }
        }

        var path = '/admin/pharmacies';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var status = res.status;

        res = await res.json();

        console.log("status is", status);

        if (status == 200) {
            message.success('Pharmacy updated successfully')

        } else {
            message.error("Oops something went wrong")
        }
    }
    onPtScrollEndReached = () => {
        this.ptGetMoreCount++;
        this.ptSkip = this.ptGetMoreCount * this.ptLimit;
        this.getAlottedPatients();
    }
    patientList = []


    doctorDetail = {
        email: '',
        phone: '',
        address: ''
    }
    doctorList = [

    ]
    handleAddDoctor() {
        console.log('add doctor');
    }
    setSelectedDoctor(id) {
        console.log(id);
    }
    setDoctorDetails(key, value) {
        this.patientDetails[key] = value;
    }

    getDoctorDetails() {
        console.log('Get patient details called');
    }
    handleSearch(context) {
        console.log(context);
    }
    handleFilter(context) {
        console.log(context);
    }
    handleNav(nav) {
        console.log(nav);
    }
    selectedDoctorDetails = {
        id: "1",
        name: "doctor 3",
        age: "22",
        gender: "M",
        email: "yashwandhare124@gmail.com",
        phone: "+1 234 567 98",
        address: "124 Villa Clinic Honover, MD 20176",
        homeClinic: "124 Villa Clinic Honover, MD 20176",
        AssignedTechnician: {
            image: "",
            name: "John Doe",
            email: "yashwandhare124@gmail.com",
            phone: "+1 234 567 98",
        },
        PatientsAlloted: [
            {
                name: "patient 1",
                age: "20",
                gender: "M"
            },
            {
                name: "patient 2",
                age: "21",
                gender: "F"
            },
            {
                name: "patient 3",
                age: "22",
                gender: "F"
            }
        ]
    }


    updateActiveStatus = async () => {
        var status;
        dbstore.checkSession()

        if ('status' in this.selectedDoctorDetails) {
            status = this.selectedDoctorDetails.status == 'active' ? 'inactive' : 'active'
        } else {
            status = 'inactive'
        }

        var obj = {}
        obj.status = status;
        obj.docid = this.selectedDoctorDetails._id;

        var raw = obj
        var path = '/admin/doctors';

        var res = await fetch(
            `${dbstore.endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw)
            },

        );
        var s = res.status;

        res = await res.json();

        console.log(res, 'doc update')

        if (s == 200) {
            message.success('Doctor activation changed')
            this.selectedDoctorDetails.status = status
        } else {
            message.error("Oops something went wrong")
        }



    }




}

const doctorStore = new Store();
export default doctorStore;