import { Col, Container, Image, Row } from "react-bootstrap";
import logo from "../assets/images/logo@2x.png";
import logoName from "../assets/images/logoname@2x.png";
import playStoreBadge from "../assets/images/google-play-badge.png";
import appStoreBadge from "../assets/images/app-store-badge.svg";

const GetTheApps = (props) => {
  console.log(props);
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center w-100 my-5">
      <nav className="d-flex flex-column justify-content-between align-items-center w-100">
        <Col xs={8} sm={6} md={4} className="mb-4">
          <Image src={logo} fluid />
        </Col>
        <Col xs={8} sm={6} md={4}>
          <Image src={logoName} fluid />
        </Col>
      </nav>
      <Row className="align-items-center my-5">
        <Col>
          <a href="https://play.google.com/apps/internaltest/4699759341412469094">
            <Image src={playStoreBadge} fluid height={80} width={175} />
          </a>
        </Col>
        <Col>
          <a href="https://play.google.com/apps/internaltest/4699759341412469094">
            <Image src={appStoreBadge} fluid />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default GetTheApps;
