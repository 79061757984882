import { Col, Row, Layout } from 'antd'
import React, { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap';
import Technician from '../../assets/images/pharmacist.png';
import Clinic from '../../assets/images/XMLID_412_.png';
import Patient from '../../assets/images/patientIco.png';
import Doctor from '../../assets/images/Path 37556.png';
import ListEle from '../common/ListEle';
import Styles from '../Styles/HomeStyle.module.css';
import homeStore from '../../dataLayer/home';
import AddModal from '../modals/AddModal';
import { observer } from 'mobx-react';

var box = {
  minHeight: 100,
  backgroundColor: "white",
  margin: 10,
  // width:"fit-content",
  padding: 20,
  borderRadius: 20,
  color: "#43425d",
  boxShadow: "1px 1px 8px 4px #e1e1e1"
};
var headparam = {
  fontSize: "4em",
  fontWeight: "bold",
  color: "#a3a0fb"
}
var heading = {
  fontWeight: "bold",
}
const Home = observer(({ props }) => {
  useEffect(() => {
    homeStore.getHome()
  }, [])
  const details = homeStore?.homeData;
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState(null);
  const handleModal = (title) => {
    setTitle(title);
    setShow(!show);
  }
  return (
    <>
      <AddModal
        show={show}
        title={title}
        handleAdd={{
          "Add Doctor": homeStore?.handleAddDoctor,
          "Add Patient": async () => {
            await homeStore.handleAddPatient(); setShow(false)
          },
          "Add Technician": homeStore?.handleAddTechnician
        }[title]}
        handleClose={() => setShow(!show)}
      />
      <Row gutter={[32, 32]} style={{ padding: "0em 5em" }}>
        <Col span={24}>
          <div className={Styles.top}>
            <div>
              <h2>Overview</h2>
            </div>
            <div>
              <a onClick={() => handleModal("Add Doctor")}>Add Doctor</a>
              <a onClick={() => handleModal("Add Patient")}>Add Patient</a>
              <a onClick={() => handleModal("Add Technician")}>Add Technician</a>
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div style={box}>
            <Row>
              <Col>
                <Image src={Clinic} ></Image>
              </Col>
              <Col>
                <h3>Clinics</h3>
              </Col>
            </Row>
            <h1 style={headparam}>{details?.Clinics}</h1>
          </div>
        </Col>
        <Col span={6}>
          <div style={box}>
            <Row>
              <Col>
                <Image src={Doctor} ></Image>
              </Col>
              <Col>
                <h3>Doctors</h3>
              </Col>
            </Row>
            <h1 style={headparam}>{details?.Doctors}</h1>
          </div>
        </Col>
        <Col span={6}>
          <div style={box}>
            <Row>
              <Col>
                <Image src={Patient} ></Image>
              </Col>
              <Col>
                <h3>Patients</h3>
              </Col>
            </Row>
            <h1 style={headparam}>{details?.Patients}</h1>
          </div>
        </Col>
        <Col span={6}>
          <div style={box}>
            <Row>
              <Col>
                <Image src={Technician} ></Image>
              </Col>
              <Col>
                <h3>Technicians</h3>
              </Col>
            </Row>
            <h1 style={headparam}>{details?.Technicians}</h1>
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <h2 style={heading}>Recent Patients</h2>
            <div style={{ maxHeight: "45vh", overflowY: "scroll" }}>
              {
                homeStore.patientList.map((item, index) => {
                  return (
                    <ListEle item={item} patient />
                  );
                })
              }
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div style={box}>
            <h2 style={heading}>Recent Requests</h2>
            <div style={{ maxHeight: "45vh", overflowY: "scroll" }}>
              {
                homeStore.requestList.map((item, index) => {
                  return (
                    <ListEle item={item} />
                  );
                })
              }
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
});
export default Home;